import GetNews from "./NewsFeed/GET/GetNews"
import GetNewsCategory from "./NewsFeed/GET/GetNewsCategory"
import PostNewDisLikes from "./NewsFeed/POST/PostNewDisLike"
import PostNewLikes from "./NewsFeed/POST/PostNewLike"
import GetGrievancePost from "./GrivanceFeed/GET/GetGrievancePost"
import GetGrievanceCategory from "./GrivanceFeed/GET/GetGrievanceCategory"
import { PostGrievanceLike } from "./GrivanceFeed/POST/PostGrievanceLike"
import { PostGrievanceDislike } from "./GrivanceFeed/POST/PostGrievanceDislike"
import GetAllDistricts from "./Directory/GET/GetAllDistrict"
import GetAllStateDirectory from "./Directory/GET/GetAllState"
import GetNewsDetails from "./LandingPage/GET/GetNewsDetails"
import GetUserNewsUploads from "./Profile/Get/GetUserNewsUploads"
import GetUserProfile from "./Profile/Get/GetUserProfile"
 const apis ={
    GetNews,
    GetNewsCategory,
    PostNewDisLikes,
    PostNewLikes,
    GetGrievancePost,
    GetGrievanceCategory,
    PostGrievanceLike,
    PostGrievanceDislike,
    GetAllDistricts,
    GetAllStateDirectory,
    GetNewsDetails,
    GetUserNewsUploads,
    GetUserProfile

}

export default apis