import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { get, post } from "../../Utils/Functions/Methods";
import { AuthContext } from "../../Context/AuthContext";
import Reporticon from "../../Assets/Icons/Reporticon.svg";
import closeicon1 from "../../Assets/Icons/close-icon1.png"
export default function Index(id: any) {
  const [RegisterModal, setRegisterModal] = useState(false);
  const handleClose = () => setRegisterModal(false);
  const [reason, setReason] = useState<any>("");

  const news_reason = async () => {
    get(`/newsPost/reason`)
      .then((response: any) => {
        setReason(response[1]);
      })

      .catch((error) => {
        console.log("Inventory fetch error", error);
      });
  };
  useEffect(() => {
    news_reason();
  }, []);

  const initialValues = {
    issue_description: "",
    reason_id: null,
  };

  const [reportReason, setreportReason] = React.useState(initialValues);
  const onValueChange = (key: any) => (value: any) => {
    setreportReason({ ...reportReason, [key]: value });
  };

  const auth = useContext<any>(AuthContext);

  const handleReport = async (id: any) => {
    const temp = reportReason;
    // console.log("temp---  ", temp);
    // console.log("temp---  ", id?.id);
    // console.log("temp---  ", auth?.userInfo[0]?.user_id);

    await post(
      `/user/${auth?.userInfo[0]?.user_id}/news/${id?.id}/report`,
      temp
    )
      .then(([status, res]) => {
        if (status === 200) {
          alert("Submit Successfully");
          setRegisterModal(false);
          setreportReason(initialValues);
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => setRegisterModal(true)}
      >
        <img className="reporticon mt-0.5" src={Reporticon} alt="Report" />
      </div>

      <Modal show={RegisterModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Report</Modal.Title>
          <img
            className="absolute right-3 mt-2 hover:scale-110 transition-transform duration-300"
            src={closeicon1}
            alt="close"
            onClick={() => setRegisterModal(false)}
          ></img>
        </Modal.Header>
        <Modal.Body className="text-center text-lg font-bold">
          Why you report this content?
        </Modal.Body>
        <Modal.Body>
          <select
            id="Reason"
            className="w-full p-2 mb-4 text-base border rounded-lg"
            onChange={(e) => onValueChange("reason_id")(e?.target?.value)}
          >
            <option selected disabled cursor-none>
              ---Select Reason---
            </option>
            {Array.isArray(reason) &&
              reason.map((reasonData: any, idx: any) => (
                <option key={idx} value={reasonData?.id}>
                  {reasonData?.reason}
                </option>
              ))}
          </select>
          <textarea
            id="message"
            className="p-3 w-full text-base rounded-lg border"
            placeholder="Type Reason..."
            onChange={(e) =>
              onValueChange("issue_description")(e?.target?.value)
            }
          ></textarea>
          <br />
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 
          font-bold rounded-md h-10 w-24 text-lg  dark:bg-blue-600 dark:hover:bg-blue-700 
          focus:outline-none dark:focus:ring-blue-800"
            onClick={() => handleReport(id)}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
