import React, { createContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { get } from "../../Utils/Functions/Methods";

interface ForgotPasswordMethods {
  requestCode: (username: any) => Promise<any>;
  setPassword: (username: any, code: any, password: any) => Promise<any>;
}
interface AuthContextValue {
  isSigned: boolean;
  setIsSigned: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthenticating: boolean;
  setIsAuthenticating: React.Dispatch<React.SetStateAction<boolean>>;
  user: any; // Update the type according to your user object structure
  setUser: React.Dispatch<React.SetStateAction<any>>;
  signIn: (username: any, password: any) => Promise<any>;
  userAttributes: any; // Update the type according to your user attributes object structure
  profileDetails: any; // Update the type according to your profile details object structure
  setProfileDetails: React.Dispatch<React.SetStateAction<any>>;
  userInfo: any; // Update the type according to your user info object structure
  signOut: () => Promise<void>;
  isAuthenticated: boolean | null;
  forgotPassword: ForgotPasswordMethods;
}

export const AuthContext = createContext<AuthContextValue | any>(null);

export const AuthProvider = (props: any) => {
  const [profileDetails, setProfileDetails] = useState({});
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [userAttributes, setUserAttributes] = useState<any>({});
  const [user, setUser] = useState<any>(null);
  const [idToken, setIdToken] = useState<any>(null);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<any>(false);

  const getUserInfo = async (user_id: any) => {
    return await get(`/users/${user_id}`)
      .then((result: any) => {
        console.log("result>>>>>>>>>", result[1]);
        return result[1];
      })
      .catch((err) => {
        return err;
      });
  };

  //cognito signin function
  async function signIn(username: any, password: any) {
    try {
      const user = await Auth.signIn(username, password);
      setUser(user);
      setUserAttributes(user?.attributes);

      setIdToken(user?.signInUserSession?.idToken?.jwtToken);
      const userInfoRes = await getUserInfo(user?.attributes?.sub);
      console.log("USER INFO RES IN AUTH CONTEXT", userInfoRes);
      setUserInfo(userInfoRes);
      setIsAuthenticated(true);

      setIsSigned(true);
      return { statusCode: 200, redirectTo: "/" };
    } catch (error) {
      // console.log("error signing in", error);
      return user;
    }
  }
  async function fetchUser() {
    try {
      const session = await Auth.currentSession(); //returns tokens

      setIdToken(session?.getIdToken());

      const authenticatedUser = await Auth.currentAuthenticatedUser();

      setUser(authenticatedUser);

      const userInfoRes = await getUserInfo(authenticatedUser?.attributes?.sub);

      setUserInfo(userInfoRes);

      setIsAuthenticated(true);

      // await getUserData(authenticatedUser?.attributes);

      setIsSigned(true);

      setUserAttributes(authenticatedUser?.attributes);

      return authenticatedUser;
    } catch (error) {
      // console.log("fetchUser >", error);
    }
  }

  const forgotPassword = {
    requestCode: async (username: any) => {
      setIsLoading(true);
      const res = await Auth.forgotPassword(username)
        .then((_) => {
          // console.log(_);
        })
        .catch((err) => console.log("err", err));
      setIsLoading(false);
      // console.log("code sent", username);
      return res;
    },
    setPassword: async (username: any, code: any, password: any) => {
      setIsLoading(true);
      const res = await Auth.forgotPasswordSubmit(username, code, password)
        .then((_) => {
          // console.log(_);
        })
        .catch((err) => console.log("err", err));
      setIsLoading(false);
      console.log(username, code, password);
      return res;
    },
  };

  const signOut = async () => {
    await Auth.signOut()

      .then((res) => {
        console.debug("signout res", res);

        setIsAuthenticated(false);

        setUser(null);
      })

      .catch((err) => {
        console.log("err in signout", err);
      });
  };
  useEffect(() => {
    // prevent logged in user from log out
    fetchUser().then((_) => setIsAuthenticating(false));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isSigned,
        setIsSigned,
        isAuthenticating,
        setIsAuthenticating,
        user,
        setUser,
        signIn,
        userAttributes,
        profileDetails,
        setProfileDetails,
        userInfo,
        signOut,
        isAuthenticated,
        forgotPassword,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
