import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import "./style.css";
import Visibility from "../../../Assets/Svg/visibility.svg";
import Visibilityoff from "../../../Assets/Svg/visibility_off.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BeatLoader } from "react-spinners";

function Index() {
  const initialCred = {
    username: "",
    password: "",
  };
  const [loginCred, setLoginCred] = useState(initialCred);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop: any) => (value: any) => {
    setLoginCred({ ...loginCred, [prop]: value });
  };

  const { setIsSigned, signIn, setUser } = useContext<any>(AuthContext);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    username: yup
      .string()

      .required("E-mail is Required")

      .min(5, "min. 5 char Required"),

    password: yup

      .string()

      .required("Password is Required")

      .min(8, "Min. 8 char Required"),
  });

  const {
    register,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const [loginError, setLoginError] = useState("");
  const loginChange = async () => {
    setIsLoading(true);
    console.log("logincred", loginCred.username, loginCred.password);
    const res = await signIn(loginCred.username, loginCred.password);
    try {
      if (res?.statusCode === 200) {
        setIsLoading(false);
        console.log("RESPONSE STATE", res);
        setUser(true);
        navigate("/");
        return { statusCode: 200, redirectTo: "/" };
      } else if (res.statusCode === 400) {
        setIsLoading(false);
        console.log("RESPONSE STATE", res);
        alert("Something Went Wrong");
      } else if (res.statusCode === 201) {
        setIsLoading(false);
        alert("Invalid credentials");
        console.log("201>>>>>", res);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("RESPONSE STATE", res);
      alert("Check your credentials");
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const passwordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="Login-component flex flex-col gap-5">
          <div className="flex flex-col">
            <div>
              <input
                placeholder="Email"
                className="input-box"
                {...register("username")}
                value={loginCred?.username}
                onChange={(_) => {
                  console.log("first name ", loginCred?.username);
                  handleChange("username")(_?.target?.value);
                }}
              />
            </div>
            <div>
              <span className="text-danger alert-text">
                {errors?.username && errors?.username.message?.toString()}
              </span>
            </div>
          </div>

          <div className="d-flex row-auto">
            <div className="flex flex-col">
              <div className="flex">
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter password"
                  className="input-box"
                  {...register("password")}
                  value={loginCred?.password}
                  onChange={(_) => {
                    handleChange("password")(_?.target?.value);
                  }}
                />
                <div className="Visibility">
                  {passwordShown ? (
                    <img
                      src={Visibility}
                      alt="toggle password visibility"
                      onClick={passwordVisibility}
                    />
                  ) : (
                    <img
                      src={Visibilityoff}
                      alt="toggle password visibility"
                      onClick={passwordVisibility}
                    />
                  )}
                </div>
              </div>
              <div>
                <span className="text-danger alert-text">
                  {errors?.password && errors?.password.message?.toString()}
                </span>
              </div>
            </div>
          </div>

          <Link to="../ForgetPassword" className="Forgot-password">
            Forgot Password?
          </Link>
          {isLoading ? (
            <div className="flex justify-center">
              <BeatLoader />
            </div>
          ) : (
            <div className="flex justify-center">
              <button
                type="button"
                className="Login-button text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-md"
                onClick={() => loginChange()}
              >
                Login
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Index;
