import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../Layout/NavBar";
import Phone from "../../Assets/Images/Phone.svg";
import Playstore from "../../Assets/Images/Playstore.svg";
import Appstore from "../../Assets/Images/Appstore.svg";
import Footer from "../../Layout/Footer";
import "./style.css";
import { Link } from "react-router-dom";
import { get } from "../../Utils/Functions/Methods";
import { AuthContext } from "../../Context/AuthContext";
import { PulseLoader } from "react-spinners";
import "animate.css";
import apis from "../../api";

function Index() {
  const [newsDetails, setNewsDetails] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const auth = useContext<any>(AuthContext);
  const fetchNewsDetails = async () => {
    const newsData = await apis.GetNewsDetails();
    if (newsData) {
      setNewsDetails(newsData);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchNewsDetails();
  }, []);

  return !auth?.isAuthenticated ? (
    <div>
      <NavBar />
      <div className="scrollable-section ">
        <div className="landingpage-section-1">
          <div>
            <p className="landingpage-heading text-4xl text-center font-semibold leading-loose">
              Just Go Real - Know the World
            </p>

            <p className="landingpage-maintext max-w-3xl text-2x1 text-center leading-relaxed">
              We provide the Latest News and Video updates crisply. Also, You
              can use the app to express your grievance and You can observe the
              Global issues at your hand, We provide the Latest News and Video
              updates crisply. Also, You can use the app to express your
              grievance.
            </p>
            {/* <div className="flex flex-wrap justify-center items-center gap-4">
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.xbi4.consdroid_app"
              >
                <img className="store-image" src={Playstore} alt="Playstore" />
              </a>
              <a
                target="_blank"
                href="https://www.apple.com/in/search/consdrid?src=globalnav"
              >
                <img className="store-image" src={Appstore} alt="Appstore" />
              </a>
            </div> */}
          </div>
          <div>
            <img
              src={Phone}
              alt="Phone"
              className="landingpage-mobile animate__animated animate__backInDown animate__delay-2s"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-wrap flex-col ">
          {!isLoading && <p>No items found</p> && newsDetails?.length == 0}
          {isLoading && (
            <PulseLoader margin={10} className="flex justify-center" />
          )}
          {!isLoading &&
            newsDetails?.length > 0 &&
            newsDetails?.map((lpdata: any, idx: any) => {
              if (idx >= newsDetails?.length - 3) {
                return (
                  <div className="landing-page-section2 ">
                    <div className="News-Section flex gap-5">
                      <img
                        className=" w-64 h-64"
                        src={lpdata?.image_url}
                        alt="Newsimage"
                      />
                      <div>
                        <div className="News-Headline text-lg font-semibold">
                          {lpdata?.news_headline}
                        </div>
                        <div className="News-Description text-md">
                          {lpdata?.Time}
                        </div>
                        <div className="News-Description text-md leading-relaxed">
                          {lpdata?.news_description}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return null; // Skip rendering for other news details
            })}
          <div className="flex flex-wrap justify-center">
            <button
              type="button"
              className="button-Landingpage text-white bg-blue-700 hover:bg-blue-800
           rounded-sm"
            >
              <Link to="/authpage" className="hover:text-white ">
                Signup to view more content
              </Link>
            </button>
          </div>
        </div>
      </div>
      <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      <Footer />
    </div>
  ) : null;
}

export default Index;
