import { post } from "../../../Utils/Functions/Methods";

const PostNewLikes = async (user_id: any, newsId: any, temp: any) => {
  try {
    const [status, res] = await post(
      `/user/${user_id}/newslikes/${newsId}`,
      temp
    );

    if (status === 200) {
      console.log("Success");
    } else {
      console.log("Something went wrong");
    }

    return res; // Return the response data
  } catch (err) {
    console.log(err);
    return null;
  }
};

export default PostNewLikes;
