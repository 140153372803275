import { get } from "../../../Utils/Functions/Methods/index";

const GetUserProfile = async (userId:any) => {
  try {
    const endpoint = `/users/${userId}`;
    const response:any = await get(endpoint);
    console.log("Profile details----", response[1]);
    return response[1]; // Return the response data
  } catch (error) {
    console.log("profile fetch error", error);
    return null; // Return null or an appropriate value for error cases
  }
};

export default GetUserProfile;