import { get } from "../../../Utils/Functions/Methods/index";

const GetUserNewsUploads = async (userId:any) => {
  try {
    const endpoint = `/users/${userId}/newsUploads/grievUploads`;
    const response:any = await get(endpoint);
    console.log("response", response[1]);
    return response[1]; // Return the response data
  } catch (error) {
    console.log("error", error);
    return null; // Return null or an appropriate value for error cases
  }
};

export default GetUserNewsUploads;