import "./App.css";
import { AuthProvider } from "./Context/AuthContext";
import Router from "./Router";

function App() {
  console.log(process.env.REACT_APP_API_URL,"app url hjyvg see here")
  return (
    <>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </>
  );
}

export default App;
