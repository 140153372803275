import React, { useState } from "react";
import State from "./Component/State";
import District from "./Component/District";
import "./style.css";
function Index() {
  const [changeDetails, setChangeDetails] = useState("State");
  const switchDetails = () => {
    switch (changeDetails) {
      case "State":
        return <State />;
      case "District":
        return <District />;
    }
  };
  const handleTabClick = (tabName: any) => {
    setChangeDetails(tabName);
  };
  return (
    <>
      {/* <div>
        <div className="Box">
          <p
            className="Box-content px-1 rounded-md hover:bg-white"
            onClick={() => setChangeDetails("State")}
          >
            State
          </p>
          <p
            className="Box-content px-1 rounded-md hover:bg-white"
            onClick={() => setChangeDetails("District")}
          >
            District
          </p>
        </div>

        <div>{switchDetails()}</div>
      </div> */}
      <div className="">
        <div className="Box ">
          <p
            className={`Box-content px-1 rounded-md hover:scale-110 transform transition duration-300 hover:underline hover:text-blue-600 focus:text-blue-400 ${
              changeDetails === "State" ? "bg-white" : ""
            } ${changeDetails === "State" ? "text-blue-600" : ""}`}
            onClick={() => handleTabClick("State")}
          >
            State
          </p>
          <p
            className={`Box-content px-1 rounded-md hover:scale-110 transform transition duration-300 hover:underline hover:text-blue-600 ${
              changeDetails === "District" ? "bg-white" : ""
            } ${changeDetails === "District" ? "text-blue-600" : ""}`}
            onClick={() => handleTabClick("District")}
          >
            District
          </p>
        </div>

        <div>
          {changeDetails === "State" && <State />}
          {changeDetails === "District" && <District />}
        </div>
      </div>
    </>
  );
}

export default Index;
