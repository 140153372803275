import React, { useContext } from "react";

import { AuthContext } from "../../Context/AuthContext";

import Navbar from "../TopNav/index";

import LandingPage from "../../Pages/LandingPage/index";

function Index() {
  const auth = useContext<any>(AuthContext);

  return auth?.isAuthenticated ? (
    <>
      <Navbar />
    </>
  ) : (
    <LandingPage />
  );
}

export default Index;
