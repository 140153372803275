import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { post } from "../../../Utils/Functions/Methods/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Visibility from "../../../Assets/Svg/visibility.svg";
import Visibilityoff from "../../../Assets/Svg/visibility_off.svg";
import { BeatLoader } from "react-spinners";

function Index() {
  const [verifyModal, setVerifyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setVerifyModal(false);
  const handleShow = () => setVerifyModal(true);
  const navigate = useNavigate();

  const initialData = {
    cognito_key: "",
    first_name: "",
    last_name: "",
    email: "",
    mobileno: "",
    city: "",
    district: "",
    state: "",
    country: "",
    zip_code: "",
    user_aadhar: "",
    is_journalist: 1,
    is_verified: 0,
    is_subscribed: 1,
    password: "",
    confirmpassword: "",
  };

  const [userData, setUserData] = useState(initialData);
  const handleChange = (key: any) => (value: any) => {
    setUserData({ ...userData, [key]: value });
  };

  const schema = yup.object().shape({
    first_name: yup

      .string()

      .required("First Name is Required")

      .min(2, "Min. 2 char Required"),

    last_name: yup

      .string()

      .required("Last Name is Required")

      .min(1, "Min. 1 char Required"),

    email: yup
      .string()

      .required("E-mail is Required")

      .min(5, "min. 5 char Required"),

    password: yup

      .string()

      .required("Password is Required")

      .min(8, "Min. 8 char Required"),

    confirmpassword: yup

      .string()

      .required("Re-enter the Password"),
  });

  const {
    register: registerForm1,
    formState: { errors: form1Errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const validate = () => {
    return Boolean(
      userData?.email &&
        userData?.first_name &&
        userData?.last_name &&
        userData?.password === userData?.confirmpassword &&
        userData?.password?.length >= 8
    );
  };
  const isPasswordValid = (password: any) => {
    // Check if the password meets all the criteria
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumericDigit = /\d/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(
      password
    );

    return (
      password?.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumericDigit &&
      hasSpecialCharacter
    );
  };

  const [isDisable, setIsDisable] = useState(true);
  useEffect(() => {
    setIsDisable(!validate());
  }, [userData?.email, userData?.password, userData?.confirmpassword]);
  const handleSubmit = async () => {
    setIsLoading(true);
    const { password, email, first_name, last_name, mobileno } = userData;

    console.log("signup form", userData?.password);

    if (!isPasswordValid(userData?.password)) {
      alert(
        "Password needs to meet the below conditions, Kindly check that \n" +
          " \n " +
          // " \n" +
          " • Minimum length (e.g., at least 8 characters). \n" +
          " • At least one uppercase letter. \n" +
          " • At least one lowercase letter. \n" +
          " • At least one numeric digit.\n" +
          " • At least one special character (e.g., @, #, $, etc.). \n"
      );
      setIsLoading(false);
      return; // Prevent further execution
    }
    try {
      await Auth.signUp({
        username: email || mobileno,

        password: password,

        attributes: {
          "custom:first_name": first_name,

          "custom:last_name": last_name,
        },
      }).then(async (signUpResponse) => {
        const formData = {
          ...userData,
          cognito_key: signUpResponse?.userSub,
        };

        await post(`/users`, formData).then(([status, res]) => {
          if (status === 200) {
            setIsLoading(false);
            handleShow();
          } else {
            alert("Sign up failed");
            setIsLoading(false);
            console.log("result sign up failed", res);
          }
        });
        // handleShow();
      });
    } catch (error) {
      setIsLoading(false);
      console.log("error signing up:", error);
      if (
        error ===
        "UsernameExistsException: An account with the given email already exists"
      ) {
        alert("Given mail Aleady Exists");
      } else {
        alert("Check the fields");
      }
    }
  };

  const verCred = {
    username: "",

    otp: "",
  };

  const [verAtt, setVerAtt] = useState(verCred);

  const handleChangeV = (prop: any) => (value: any) => {
    setVerAtt({ ...verAtt, [prop]: value });
  };

  const schema1 = yup.object().shape({
    username: yup

      .string()

      .required("Email is Required")

      .min(5, "Min. 5 char Required"),

    otp: yup

      .string()

      .required("OTP is Required")

      .min(4, "Min. 4 char Required"),
  });

  const {
    register: registerForm2,
    formState: { errors: form2Errors },
  } = useForm({
    resolver: yupResolver(schema1),
    mode: "onBlur",
  });

  const [loading, setLoading] = useState(false);

  const handleVerify = async () => {
    setLoading(true);
    const res = await Auth.confirmSignUp(
      userData?.email,

      verAtt?.otp
    )
      .then(([status, res]) => {
        if (status === "S") {
          setLoading(false);
          alert("verification done");
          setVerifyModal(false);
          navigate("/login");
        } else {
          setLoading(false);

          alert("verification error");
          // setIsSigned(false)
        }
      })
      .catch((e) => {
        setLoading(false);

        console.log("error verify otp", e);
        // setIsSigned(false);
      });
  };

  const [password, setPassword] = useState(false);
  const passwordVisibility = () => {
    setPassword(!password);
  };

  const tooltip = (
    <Tooltip id="tooltip">
      Password Policy:
      <br />
      - Minimum 8 characters
      <br />
      - Must contain a combination of letters, numbers, and symbols
      <br />
    </Tooltip>
  );

  return (
    <>
      <div className="flex justify-center items-center">
        <div className="Signup-component">
          <div className="flex flex-col">
            <div>
              <input
                placeholder="First Name"
                className="input-box"
                {...registerForm1("first_name")}
                value={userData?.first_name}
                onChange={(_) => {
                  handleChange("first_name")(_?.target?.value);
                }}
              />
            </div>
            <div>
              <span className="text-danger alert-text">
                {form1Errors?.first_name &&
                  form1Errors?.first_name.message?.toString()}
              </span>
            </div>
          </div>

          <div className="flex flex-col">
            <div>
              <input
                placeholder="Last Name"
                className="input-box"
                {...registerForm1("last_name")}
                value={userData?.last_name}
                onChange={(_) => {
                  handleChange("last_name")(_?.target?.value);
                }}
              />
            </div>
            <div>
              <span className="text-danger alert-text">
                {form1Errors?.last_name &&
                  form1Errors?.last_name.message?.toString()}
              </span>
            </div>
          </div>

          <div className="flex flex-col">
            <div>
              <input
                placeholder="Email"
                className="input-box "
                {...registerForm1("email")}
                value={userData?.email}
                onChange={(_) => {
                  handleChange("email")(_?.target?.value);
                }}
              />
            </div>
            <div>
              <span className="text-danger alert-text">
                {form1Errors?.email && form1Errors?.email.message?.toString()}
              </span>
            </div>
          </div>

          <div className="d-flex row-auto">
            <div className="flex flex-col">
              <div className="flex">
                <input
                  type={password ? "text" : "password"}
                  placeholder="Password"
                  className="input-box"
                  {...registerForm1("password")}
                  value={userData?.password}
                  onChange={(_) => {
                    handleChange("password")(_?.target?.value);
                  }}
                />

                {/* <div className="Visibility">
                  {password ? (
                    <img
                      src={Visibility}
                      alt="toggle password visibility"
                      onClick={passwordVisibility}
                    />
                  ) : (
                    <img
                      src={Visibilityoff}
                      alt="toggle password visibility"
                      onClick={passwordVisibility}
                    />
                  )}
                </div> */}
              </div>
              <OverlayTrigger placement="top" overlay={tooltip}>
                <p>Password policy</p>
              </OverlayTrigger>
              <div>
                <span className="text-danger alert-text">
                  {form1Errors?.password &&
                    form1Errors?.password.message?.toString()}
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex row-auto">
            <div className="flex flex-col">
              <div className="flex">
                <input
                  type={password ? "text" : "password"}
                  placeholder="Confirm Password"
                  className="input-box"
                  {...registerForm1("confirmpassword")}
                  value={userData?.confirmpassword}
                  onChange={(_) => {
                    console.log("confirmpassword", userData?.confirmpassword);
                    handleChange("confirmpassword")(_?.target?.value);
                  }}
                />
                <div className="Visibility">
                  {password ? (
                    <img
                      src={Visibility}
                      alt="toggle password visibility"
                      onClick={passwordVisibility}
                    />
                  ) : (
                    <img
                      src={Visibilityoff}
                      alt="toggle password visibility"
                      onClick={passwordVisibility}
                    />
                  )}
                </div>
              </div>
              <div>
                <span className="text-danger alert-text">
                  {form1Errors?.confirmpassword &&
                    form1Errors?.confirmpassword.message?.toString()}
                </span>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center">
              <BeatLoader />
            </div>
          ) : (
            <div className="flex justify-center">
              <button
                type="button"
                className="Signup-button text-white bg-blue-700
               hover:bg-blue-800  rounded-sm"
                onClick={handleSubmit}
                disabled={isDisable}
              >
                Signup
              </button>
            </div>
          )}
        </div>
        <Modal show={verifyModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Verify Account</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body className="text-center">
            We have sent OTP to {userData?.email}
          </Modal.Body> */}
          <Modal.Body className="text-center">
            <div className="flex flex-col">
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email address"
                  {...registerForm2("username")}
                  value={userData?.email}
                />
              </div>
              <div>
                <span className="text-danger">
                  {form2Errors?.username &&
                    form2Errors?.username.message?.toString()}
                </span>
              </div>
            </div>

            <br />

            <div className="flex flex-col">
              <div>
                <input
                  className="form-control"
                  placeholder="OTP"
                  {...registerForm2("otp")}
                  value={verAtt?.otp}
                  onChange={(_) => {
                    handleChangeV("otp")(_?.target?.value);
                  }}
                />
              </div>
              <div>
                <span className="text-danger">
                  {form2Errors?.otp && form2Errors?.otp.message?.toString()}
                </span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={handleVerify}>
              verify
            </Button> */}
            {loading ? (
              <div className="flex justify-center">
                <BeatLoader />
              </div>
            ) : (
              <Button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 
          font-bold rounded-md h-10 w-24 text-lg  dark:bg-blue-600 dark:hover:bg-blue-700 
          focus:outline-none dark:focus:ring-blue-800"
                onClick={handleVerify}
              >
                Verify
                {/* <a href="/AuthPage">VERIFY</a> */}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Index;
