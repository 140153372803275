import { get } from "../../../Utils/Functions/Methods/index";
const GetGrievanceCategory = async () => {
  try {
    const response:any = await get("/grievancePost/category"); // assuming `get` is an async function
    return response[1]; // assuming the data you need is at index 1
  } catch (error) {
    console.error("Grievance category fetch error", error);
    return null; // Return null or an appropriate value for error cases
  }
};

export default GetGrievanceCategory