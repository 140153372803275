import React, { useState } from "react";
import Login from "../Login";
import Signup from "../Signup";
import "./style.css";
import Loginimage from "../../../Assets/Images/Loginimage.svg";
import Logoside from "../../../Assets/Images/JGRLogo.svg";

function Index() {
  const [changeDetails, setChangeDetails] = useState("icon-button-1");
  const switchDetails = () => {
    switch (changeDetails) {
      case "icon-button-1":
        return <Signup />;
      case "icon-button-2":
        return <Login />;
      default:
        return null;
    }
  };


  return (
    <>
      <div className="mainpage-Component">
        <div className="flex justify-center items-center">
          <img className="mainpage-image" src={Loginimage} alt="Loginimage" />
        </div>
        <div className="mainpage-box-container flex shadow-md shadow-gray-700">
          <div className="mainpage-box">
            <div>
              <div className="flex flex-col">
                <div className="flex logo-mainbox">
                  <img className="Logo" src={Logoside} alt="Logo" />
                </div>
                <div className="mainpage-smallbox">
                  <div
                    className={`mx-2 mainpage-signup flex justify-center cursor-pointer text-white
                     ${
                       changeDetails == "icon-button-1"
                         ? "SelectedBtn"
                         : "unSelectBtn"
                     }`}
                    onClick={() => setChangeDetails("icon-button-1")}
                  >
                    Signup
                  </div>
                  <div
                    className={`mainpage-login flex justify-center cursor-pointer text-white  ${
                      changeDetails == "icon-button-2"
                        ? "SelectedBtn"
                        : "unSelectBtn"
                    }`}
                    onClick={() => setChangeDetails("icon-button-2")}
                  >
                    Login
                  </div>
                </div>
                <div className="mainpage-text">
                  <p>Know your world!</p>
                </div>
                <div>{switchDetails()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
