import React, { useEffect, useState } from "react";

import Search from "../../../../Assets/Svg/manage_search.svg";

import Website from "../../../../Assets/Icons/Website.svg";
import { get } from "../../../../Utils/Functions/Methods";
import { PulseLoader } from "react-spinners";
import apis from "../../../../api";

type StateDirectory = {
  state: string;
  capital: string;
  links: string;
  // Add any other properties of the object here
};
function Index() {
  const [stateDirectory, setStateDirectory] = useState<StateDirectory[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const filteredStateDirectory = stateDirectory.filter((Stdata) =>
    Stdata.state.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const StateDirectory = () => {
    get(`/govtDirectory`)
      .then((response: any) => {
        setIsLoading(false);
        console.log("District Backend", response[1]);
        setStateDirectory(response[1]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    StateDirectory();
  }, []);

  return (
    <div className="search-box">
      <div className="search-icon">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="  Search State..."
          className="search p-2"
        />
        <img src={Search} alt="search" />
      </div>
      <div>
        <p className="flex font-semibold mt-3  p-2 text-center">
          You can utilize the state and district-specific government websites to
          address your concerns and report issues effectively.
        </p>
      </div>
      <div className="directory-box" style={{ flexWrap: "wrap" }}>
        {!isLoading && <p>No items found</p> &&
          filteredStateDirectory?.length == 0}
        {isLoading && (
          <PulseLoader margin={10} className="flex justify-center" />
        )}
        {!isLoading &&
          filteredStateDirectory?.length > 0 &&
          filteredStateDirectory?.map((Stdata, idx) => {
            return (
              <div className="box-heading">
                <div className="text-2xl font-bold">{Stdata?.state}</div>
                <div className="wholebox">
                  <div className="flex flex-col gap-5">
                    <div className="flex gap-2">
                      Name of the state: {Stdata?.state}
                    </div>
                    <div className="flex gap-2">Capital: {Stdata?.capital}</div>
                    <div className="flex gap-1">
                      <img
                        className="image-website"
                        src={Website}
                        alt="Website"
                      />
                      <a
                        href={`${Stdata?.links}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {Stdata?.links}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
export default Index;
