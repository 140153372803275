import React, { useContext, useEffect, useState } from "react";
import Add from "../../Assets/Icons/Add.svg";
import { Modal } from "react-bootstrap";
import { get, post, uploadFiles } from "../../Utils/Functions/Methods";
import closeicon1 from "../../Assets/Icons/close-icon1.png";
import "./style.css";
import { AuthContext } from "../../Context/AuthContext";
import {
  getCitiesByStateAndCountry,
  getCountries,
  getStatesByCountry,
} from "../../Utils/countryApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import GrievanceReport from "../../Layout/GrievanceReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { BeatLoader, PulseLoader } from "react-spinners";
import apis from "../../api";

type State = {
  map(arg0: (state: any) => JSX.Element): React.ReactNode;
  id: number;
  name: string;
  iso2: string;
};
type City = {
  map(arg0: (state: any) => JSX.Element): React.ReactNode;
  id: number;
  name: string;
  iso2: string;
};

function Index() {
  const [RegisterModal, setRegisterModal] = useState(false);
  const handleClose = () => setRegisterModal(false);
  const [data, setData] = useState({});
  const auth = useContext<any>(AuthContext);
  const [refresh, setRefresh] = useState(false);
  const [files, setFiles] = useState<any>("");
  const grievFilePath = "grievancepost/";
  const initialValue = {
    file_name: "",
    file_path: grievFilePath,
    file_size: 25,
    file_type: "",
    posted_by: auth?.userInfo[0]?.user_id,
    anonymous_user: null,
    file_extension: "",
    grievance_state: "",
    griev_category_id: 1,
    grievance_category: "",
    grievance_city: "",
    grievance_headline: "",
    grievance_description: "",
    grievance_country: "",
  };

  const schema = yup.object().shape({
    grievance_headline: yup

      .string()

      .required("Headline is Required")

      .min(5, "Min. 5 char Required"),

    grievance_category: yup

      .string()

      .required("Select Category")

      .min(5, "Min. 5 char Required"),

    grievance_description: yup
      .string()

      .required("Description is Required")

      .min(20, "min. 20 char Required"),

    grievance_country: yup

      .string()

      .required("Select State")

      .min(5, "Min. 5 char Required"),

    grievance_state: yup

      .string()

      .required("Select State")

      .min(5, "Min. 5 char Required"),

    grievance_city: yup

      .string()

      .required("Select City")

      .min(5, "Min. 5 char Required"),

    anonymous_user: yup

      .string()

      .required("Select any one")

      .min(2, "Min. 2 char Required"),
  });
  const {
    register: register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const [grievanceDetails, setGrievanceDetails] = useState(initialValue);

  const [states, setStates] = useState<State | null>(null);
  const [cities, setCities] = useState<City | null>(null);
  const [countries, setCountries] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [getGrivData, setGetGrivData] = useState("");
  const [getGrivCategory, setGetGrivCategory] = useState("");

  const handleCountryChange = (e: any) => {
    const iso2 = e.target.value.split("-")[1];
    setGrievanceDetails({
      ...grievanceDetails,
      grievance_country: e.target.value,
    });
    getStatesByCountry(iso2).then((e) => {
      setStates(e);
    });
  };
  const handleStateChange = (e: any) => {
    const iso2 = e.target.value.split("-")[1];
    const countryIso = grievanceDetails.grievance_country.split("-")[1];
    setGrievanceDetails({
      ...grievanceDetails,
      grievance_state: e.target.value,
    });
    getCitiesByStateAndCountry(countryIso, iso2).then((e) => setCities(e));
  };
  const handleCityChange = (e: any) => {
    setGrievanceDetails({
      ...grievanceDetails,
      grievance_city: e.target.value,
    });
  };
  useEffect(() => {
    getCountries().then((e) => setCountries(e));
  }, []);

  const onGrievDetails = (key: any) => (value: any) => {
    setGrievanceDetails({ ...grievanceDetails, [key]: value });
  };

  const [base64String, setBase64String] = useState("");

  const handleFileChange = (files: any) => {
    const file = files[0];
    console.log("file", file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = String(reader.result).split(",")[1];
      setBase64String(base64String);
      setFiles(file);
    };
  };

  const [loading, setLoading] = useState(false);
  const GrivSubmit = async () => {
    setLoading(true);
    if (files === "") {
      alert("empty");
      setLoading(false);
    } else {
      const fileData = {
        file_name: files["name"].split(".")[0],
        content_type: files["type"],
        path: grievFilePath,
        extension: files["name"].split(".").pop(),
      };

      setData(fileData);

      await uploadFiles(files, fileData)
        .then(async () => {
          const { file_name, content_type, path, extension } = fileData;
          const temp = {
            ...grievanceDetails,
            ...fileData,

            f_name: file_name,
            f_type: content_type,
            f_path: path,
            f_extension: extension,
            img_base64: base64String,
          };
          console.log("Temp", temp);
          await post(`/grievancepost`, temp).then(([status, res]) => {
            if (status === 200) {
              setLoading(false);
              alert("Grievance Added Successfully");
              setGrievanceDetails(initialValue);
              reset();
              setRefresh(!refresh);
              setRegisterModal(false);
              fetchGrievance();
            } else {
              alert("Unexpected error occurred, Please try again");
              setLoading(false);
              setGrievanceDetails(initialValue);
              reset();
              console.log("result fail", res, status);
            }
          });
        })
        .catch((err) => console.log(err));
    }
  };

  const fetchGrievance = async () => {
    // setIsLoading(true);
    try {
      const response = await apis.GetGrievancePost(auth?.userInfo[0]?.user_id);
      console.log(response);
      setGetGrivData(response); // assuming the data you need is at index 1
      setIsLoading(false);
    } catch (error) {
      console.error("Grievance fetch error", error);
    }
  };

  const grievCategory = async () => {
    setIsLoading(true);
    try {
      const response = await apis.GetGrievanceCategory();
      console.log(response);
      setGetGrivCategory(response); // assuming the data you need is at index 1
      setIsLoading(false);
    } catch (error) {
      console.error("Grievance category fetch error", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchGrievance();
    grievCategory();
  }, []);

  async function handleLikeClick(grievanceId: any) {
    const temp = {};
    try {
      // Assuming apis.PostGrievanceLikes is defined and works similarly to apis.PostNewLikes
      const response = await apis.PostGrievanceLike(
        auth?.userInfo[0]?.user_id,
        grievanceId,
        temp
      );

      console.log(response);
    } catch (error) {
      console.log("error", error);
      // You can add additional error handling here if needed
    }
    fetchGrievance(); // Fetch the latest grievances
  }

  async function handleDislikeClick(grievanceId: any) {
    const temp = {};

    try {
      // Assuming apis.PostGrievanceDisLikes is defined and works similarly to apis.PostNewDisLikes
      const response = await apis.PostGrievanceDislike(
        auth?.userInfo[0]?.user_id,
        grievanceId,
        temp
      );
      console.log(response);
    } catch (error) {
      console.log("error", error);
      // You can add additional error handling here if needed
    }
    fetchGrievance(); // Fetch the latest grievances
  }

  const navigate = useNavigate();

  return (
    <div className="grievancefeed-container">
      <div className="flex flex-col">
        {/* <GrievanceCategory /> */}
        <div className="Navspace">
          <div>
            {auth?.userInfo[0]?.is_verified ? (
              <div
                className="Addcontent"
                onClick={() => setRegisterModal(true)}
              >
                <div className="flex gap-1">
                  <p>Add your Content</p>
                  <img className="Addimage" src={Add} alt="Add" />
                </div>
              </div>
            ) : (
              <div
                className="Addcontent"
                onClick={() => navigate("../Profile")}
              >
                verify with Aadhar to add grievance
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="Grievancefeed">
        {!isLoading && <p>No items found</p> && getGrivData?.length == 0}
        {isLoading && (
          <PulseLoader margin={10} className="flex justify-center" />
        )}
        {!isLoading &&
          getGrivData?.length > 0 &&
          Array.isArray(getGrivData) &&
          getGrivData?.map((lpdata, idx) => {
            return (
              <div className="flex justify-center">
                <div className="Grievancecard drop-shadow-lg">
                  <div className="Grievancecontent">
                    <img
                      className="Grievanceimage flex w-52 h-52 rounded-lg"
                      src={lpdata?.image_url}
                      alt="Grievanceimage"
                    />
                    <div>
                      <div className="flex mb-2">
                        <div className="flex gap-5">
                          <div>
                            <div className="userName">{lpdata?.name}</div>
                            <div className="city">
                              {lpdata?.grievance_state}
                            </div>
                          </div>
                          <div className="flex">
                            <div className="time">
                              {lpdata?.file_added_time &&
                                lpdata.file_added_time.slice(0, 10)}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="Headline">
                        {lpdata?.grievance_headline}
                      </div>
                      <div className="Description">
                        {lpdata?.grievance_description}
                        <div className="flex mt-3">
                          <div>
                            <div className="flex gap-5">
                              <div>
                                <FontAwesomeIcon
                                  className={`${
                                    lpdata?.is_liked
                                      ? "text-blue-500"
                                      : "text-gray-500"
                                  }`}
                                  icon={faThumbsUp}
                                  onClick={() => {
                                    handleLikeClick(lpdata?.grievance_id);
                                  }}
                                />
                                <p>{lpdata?.like_count}</p>
                              </div>
                              <div>
                                <FontAwesomeIcon
                                  className={`${
                                    lpdata?.is_disliked
                                      ? "text-red-500"
                                      : "text-gray-500"
                                  }`}
                                  icon={faThumbsDown}
                                  onClick={() => {
                                    handleDislikeClick(lpdata?.grievance_id);
                                  }}
                                  style={
                                    {
                                      // color: dislikeColor[lpdata?.grievance_id] ? 'red' : 'gray',
                                      // height: 25,
                                    }
                                  }
                                />
                                <p>{lpdata?.dislike_count}</p>
                              </div>
                              <div className="flex gap-1">
                                <GrievanceReport id={lpdata?.grievance_id} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Modal show={RegisterModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">Grievance Register</Modal.Title>
          <img
            className=" right-3 mt-2 sm:right-10 absolute md:right-5 hover:scale-110 transition-transform duration-300"
            src={closeicon1}
            onClick={() => setRegisterModal(false)}
          ></img>
        </Modal.Header>
        <Modal.Body className="modalbody">
          Type Grievance Details Here
        </Modal.Body>
        <Modal.Body className="text-center">
          <div>
            <input
              placeholder="Grievance Headlines"
              className="modalHeadline w-full p-2 text-base border rounded-lg"
              value={grievanceDetails?.grievance_headline}
              {...register("grievance_headline")}
              onChange={(e) => {
                onGrievDetails("grievance_headline")(e.target?.value);
              }}
            />
            <div>
              <span className="text-danger">
                {errors.grievance_headline &&
                  errors.grievance_headline.message?.toString()}
              </span>
            </div>
          </div>

          <br />

          <select
            id="Category"
            className="modalcategory w-full p-2 mb-4 text-base border rounded-lg"
            onChange={(_) => {
              onGrievDetails("grievance_category")(_?.target?.value);
            }}
          >
            {Array.isArray(getGrivCategory) &&
              getGrivCategory.map((categoryData: any, idx: any) => (
                <option key={idx} value={categoryData.value}>
                  {categoryData?.grievance_category_name}
                </option>
              ))}
          </select>

          <input
            onChange={(_) => handleFileChange(_.target.files)}
            type="file"
            id="formFile"
            name="files"
            className="pl-10"
          />
          <br />
          <br />
          <div>
            <textarea
              className="modaldescription p-2.5 w-full text-base rounded-lg border"
              placeholder="Type Grievance Description..."
              value={grievanceDetails?.grievance_description}
              {...register("grievance_description")}
              onChange={(e) => {
                onGrievDetails("grievance_description")(e.target?.value);
              }}
            />
            <div>
              <span className="text-danger">
                {errors.grievance_description &&
                  errors.grievance_description.message?.toString()}
              </span>
            </div>
          </div>

          <br />

          <select
            placeholder="Country"
            className="modalplace w-full p-2 text-base text-gray-500 border rounded-lg"
            onChange={handleCountryChange}
          >
            <option disabled value="">
              --Select country --
            </option>
            {countries
              ? countries.map((country: any) => (
                  <option
                    key={country.id}
                    value={`${country.name}-${country.iso2}`}
                    selected={
                      `${country.name}-${country.iso2}` ==
                      grievanceDetails.grievance_country
                    }
                  >
                    {country.name}
                  </option>
                ))
              : ""}
          </select>
          <br />
          <br />
          <select
            id="State"
            className="modalplace w-full p-2 text-base text-gray-500 border rounded-lg"
            onChange={handleStateChange}
          >
            <option selected disabled value="">
              --Select state --
            </option>
            {states
              ? states.map((state: any) => (
                  <option
                    key={state.id}
                    value={`${state.name}-${state.iso2}`}
                    selected={
                      `${state.name}-${state.iso2}` ==
                      grievanceDetails.grievance_state
                    }
                  >
                    {state.name}
                  </option>
                ))
              : ""}
          </select>
          <select
            id="City"
            className="modalplace w-full p-2 mt-3 text-base text-gray-500 border rounded-lg"
            onChange={handleCityChange}
          >
            <option selected disabled value="">
              --Select City --
            </option>
            {cities
              ? cities.map((city) => (
                  <option
                    key={city?.id}
                    value={`${city?.name}-${city?.iso2}`}
                    selected={
                      `${city?.name}-${city?.iso2}` ==
                      grievanceDetails.grievance_city
                    }
                  >
                    {city?.name}
                  </option>
                ))
              : ""}
          </select>
          <select
            id="Anonymous"
            className="modalplace w-full p-2 mt-3 text-base border rounded-lg"
            onChange={(_) => {
              onGrievDetails("anonymous_user")(_?.target?.value);
            }}
          >
            <option selected>Share as Anonymous User</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </Modal.Body>
        <Modal.Footer className="modalButton">
          {loading ? (
            <div className="flex justify-center">
              <BeatLoader />
            </div>
          ) : (
            <button
              type="button"
              className=" text-white bg-blue-700 hover:bg-blue-800  
            font-bold rounded-md h-10 w-24 text-lg"
              onClick={() => GrivSubmit()}
            >
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Index;
