import React, { useEffect, useState } from "react";
import { get } from "../../../../Utils/Functions/Methods";
import Search from "../../../../Assets/Svg/manage_search.svg";
import Website from "../../../../Assets/Icons/Website.svg";
import "./style.css";
import { PulseLoader } from "react-spinners";
import apis from "../../../../api";

type DistrictDirectory = {
  state: string;
  district: string;
  links: string;
  // Add any other properties of the object here
};

function Index() {
  const [districtDirectory, setdistrictDirectory] = useState<
    DistrictDirectory[]
  >([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const filtereddistrictDirectory = districtDirectory.filter(
    (Stdata) =>
      Stdata.district &&
      Stdata.district.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const fetchDistricts = async () => {
      const districts = await apis.GetAllDistricts();
      if (districts) {
        console.log("District Backend", districts);
        setdistrictDirectory(districts);
      }
      setIsLoading(false);
    };

    fetchDistricts();
  }, []);

  return (
    <div className="search-box">
      <div className="search-icon">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="  Search District..."
          className="search  p-2"
        />
        <img src={Search} alt="search" />
      </div>
      <div>
        <p className="flex font-semibold mt-3 p-2 text-center">
          You can utilize the state and district-specific government websites to
          address your concerns and report issues effectively.
        </p>
      </div>
      <div className="directory-box" style={{ flexWrap: "wrap" }}>
        {!isLoading && <p>No items found</p> &&
          filtereddistrictDirectory?.length == 0}
        {isLoading && (
          <PulseLoader margin={10} className="flex justify-center" />
        )}
        {!isLoading &&
          filtereddistrictDirectory?.length > 0 &&
          filtereddistrictDirectory?.map((Stdata, idx) => {
            return (
              <div className="box-heading">
                <div className="text-2xl font-bold">{Stdata?.district}</div>
                <div className="wholebox">
                  <div className="flex flex-col gap-5">
                    <div className="flex gap-2">
                      Name of the state: {Stdata?.state}
                    </div>
                    <div className="flex gap-2">
                      Capital: {Stdata?.district}
                    </div>
                    <div className="flex gap-2">
                      <img src={Website} alt="Website" />
                      <a
                        href={`${Stdata?.links}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {Stdata?.links}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
export default Index;
