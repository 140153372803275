import React, { useContext, useEffect, useState } from "react";
import Profile from "../../Assets/Images/Profile.svg";
import BlueProfile from "../../Assets/Images/BlueProfile.svg";
import Menu from "../../Assets/Images/menu.svg";
import Notification from "../../Assets/Icons/Notification.svg";
import Logout from "../../Assets/Icons/Logout.svg";
import News from "../../Assets/Icons/News.svg";
import BlueNews from "../../Assets/Icons/Newsblue.svg";
import BlueGriev from "../../Assets/Icons/Grievblue.svg";
import Grievance from "../../Assets/Icons/Grievance.svg";
import JGRcommunity from "../../Assets/Icons/JGRcommunity.svg";
import JGRcommunityBlue from "../../Assets/Icons/Community.svg";
import Directory from "../../Assets/Icons/Directory.svg";
import BlueDirectory from "../../Assets/Icons/folder.svg";
import Cancel from "../../Assets/Images/cancel.svg";
import Logo from "../../Assets/Images/Logopng.png";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate, Outlet, Link, useLocation } from "react-router-dom";
import "./style.css";

declare global {
  interface Window {
    google: any;
    googleTranslateElementInit: () => void;
  }
}

function Index() {
  const [sectionChange, setSectionChange] = useState("section1");
  const unSelectedClass = "navsection-items";
  const selectedClass = "selected-navsection-items";
  let location = useLocation();
  useEffect(() => {
    if (location.pathname === "/GrievancefeedPage") {
      setSectionChange("section2");
    } else if (location.pathname === "/JGRCommunity") {
      setSectionChange("section3");
    } else if (location.pathname === "/Directory") {
      setSectionChange("section4");
    } else if (location.pathname === "/Profile") {
      setSectionChange("section5");
    } else {
      setSectionChange("section1");
    }
  }, [location.pathname]);
  const auth = useContext<any>(AuthContext);
  // const { setAuthState, setIsSigned,auth }: any = useContext(AuthContext);
  const { setUser }: any = useContext(AuthContext);

  const signOut = async () => {
    setIsAnimating(true);
    await auth
      .signOut()
      .then(() => {
        // navigate("/AuthPage");

        setUser(null);
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);
    if (typeof window.google !== "undefined") {
      window.googleTranslateElementInit();
    } else {
      window.googleTranslateElementInit = function () {
        new window.google.translate.TranslateElement(
          { pageLanguage: "en" },
          "google_translate_element"
        );
      };
    }
  }, []);
  const [isAnimating, setIsAnimating] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  return auth?.isAuthenticated ? (
    <>
      <div className="navbar flex flex-row cursor-pointer">
        <div
          className="flex justify-start gap-5 items-center ml-3 mob-profile"
          onClick={() => navigate("Profile")}
        >
          <div
            className={`flex w-14 h-14 justify-between gap-10 ${
              sectionChange == "section5" ? selectedClass : unSelectedClass
            }`}
            onClick={() => {
              setSectionChange("section5");
            }}
            id="section5"
          >
            <img src={Profile} alt="Profile" />
          </div>
        </div>
        <div className="flex justify-between gap-10">
          <div
            className={`flex justify-between items-center gap-10 ${
              sectionChange == "section1" ? selectedClass : unSelectedClass
            }`}
            onClick={() => {
              setSectionChange("section1");
              console.log("section", sectionChange);
              navigate("/");
            }}
            id="section1"
          >
            {/* <Link to="/"> */}
            <div className="flex justify-start text-lg font-bold gap-1 image-container">
              <img
                src={sectionChange === "section1" ? BlueNews : News}
                alt="News"
                className="zoom-out"
              />
              <p className="zoom-out">News</p>
            </div>
            {/* </Link> */}
          </div>
          <div
            className={`flex justify-between  items-center gap-10 ${
              sectionChange == "section2" ? selectedClass : unSelectedClass
            }`}
            onClick={() => {
              setSectionChange("section2");
              navigate("GrievancefeedPage");
            }}
            id="section2"
          >
            {/* <Link to="../GrievancefeedPage"> */}
            <div className="flex justify-start text-lg font-bold gap-1 image-container">
              <img
                src={sectionChange == "section2" ? BlueGriev : Grievance}
                alt="Grievance"
                className="zoom-out"
              />
              <p className="zoom-out">Grievance</p>
            </div>
            {/* </Link> */}
          </div>
          <div
            className={`flex justify-between  items-center gap-10 ${
              sectionChange == "section3" ? selectedClass : unSelectedClass
            }`}
            onClick={() => {
              setSectionChange("section3");
              navigate("JGRCommunity");
            }}
            id="section3"
          >
            <div className="flex justify-start text-lg font-bold gap-3 image-container">
              <img
                src={
                  sectionChange == "section3" ? JGRcommunityBlue : JGRcommunity
                }
                alt="JGRcommunity"
                className="zoom-out"
              />
              <p className="zoom-out">JGR Community</p>
            </div>
          </div>
          <div
            className={`flex justify-between  items-center gap-10 ${
              sectionChange == "section4" ? selectedClass : unSelectedClass
            }`}
            onClick={() => {
              setSectionChange("section4");
              navigate("Directory");
            }}
            id="section4"
          >
            <div className="flex justify-start text-lg font-bold gap-1 image-container">
              <img
                src={sectionChange == "section4" ? BlueDirectory : Directory}
                alt="Directory"
                className="zoom-out"
              />
              <p className="zoom-out">Directory</p>
            </div>
          </div>
        </div>
        <div className="flex justify-start gap-5 mr-3 mob-logout">
          <img src={Notification} alt="Notification" className="bell-icon" />
          <button
            onClick={() => signOut()}
            aria-label="Log out"
            className={
              isAnimating ? "logout-button animating" : "logout-button"
            }
          >
            <img src={Logout} onClick={() => signOut()} alt="Logout" />
          </button>
        </div>

        <div className="menu-icon ">
          <div className="navbar ">
            <div>
              <Link to="/Profile">
                <img src={Profile} alt="Profile" />
              </Link>
            </div>
            <div className="logo">
              <img src={Logo} alt="Logo" />
            </div>
            <div className={`hamburger-menu  ${isOpen ? "open" : ""}`}>
              <div className="hamburger-icon" onClick={toggleMenu}>
                <img src={Menu} alt="Menu" />
              </div>

              <ul className="menu-items ">
                <div className="menu-section-1 ">
                  <div>
                    <div
                      className="cancel-btn"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <img src={Cancel} alt="Cancel" />
                    </div>
                    <div className="menu-inside ">
                      <div className="flex flex-col gap-3 ">
                        <Link to="/">
                          {/* <div className="menu-items-container"> */}
                          <div
                            className={`menu-items-container ${
                              sectionChange === "section1" ? "selected" : ""
                            }`}
                            onClick={() => setSectionChange("section1")}
                          >
                            <img
                              src={
                                sectionChange === "section1" ? BlueNews : News
                              }
                              alt="News"
                            />
                            <li>News</li>
                          </div>
                        </Link>
                        <Link to="/GrievancefeedPage">
                          {/* <div className="menu-items-container"> */}
                          <div
                            className={`menu-items-container ${
                              sectionChange === "section2" ? "selected" : ""
                            }`}
                            onClick={() => setSectionChange("section2")}
                          >
                            <img
                              src={
                                sectionChange == "section2"
                                  ? BlueGriev
                                  : Grievance
                              }
                              alt="Grievance"
                            />
                            <li>Grievance</li>
                          </div>
                        </Link>
                        <Link to="/JGRCommunity">
                          {/* <div className="menu-items-container"> */}
                          <div
                            className={`menu-items-container ${
                              sectionChange === "section3" ? "selected" : ""
                            }`}
                            onClick={() => setSectionChange("section3")}
                          >
                            <img
                              src={
                                sectionChange == "section3"
                                  ? JGRcommunityBlue
                                  : JGRcommunity
                              }
                              alt="JGRcommunity"
                            />
                            <li>JGR Community</li>
                          </div>
                        </Link>
                        <Link to="/Directory">
                          {/* <div className="menu-items-container"> */}
                          <div
                            className={`menu-items-container ${
                              sectionChange === "section4" ? "selected" : ""
                            }`}
                            onClick={() => setSectionChange("section4")}
                          >
                            <img
                              src={
                                sectionChange == "section4"
                                  ? BlueDirectory
                                  : Directory
                              }
                              alt="Directory"
                            />
                            <li>Directory</li>
                          </div>
                        </Link>
                      </div>
                      <div className="flex">
                        <button
                          onClick={() => signOut()}
                          aria-label="Log out"
                          className={
                            isAnimating
                              ? "menu-items-container logout-button animating"
                              : "menu-items-container logout-button"
                          }
                          // className={
                          //   isAnimating ? "logout-button animating" : "logout-button"
                          // }
                        >
                          <img
                            src={Logout}
                            onClick={() => signOut()}
                            alt="Logout"
                          />
                          <p className="flex text-lg mt-1">Signout</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  ) : null;
}

export default Index;
