import React from "react";
import "./style.css";

function Index(data: any) {
  // console.log("hello",data?.data[0]);
  return (
    <div className="Newsfeed">
      {data?.data?.map((item: any) => {
        return (
          <>
            <div className="flex justify-center">
              <div className="Newsbackground drop-shadow-lg">
                <div className="Newscontent">
                  <img
                    className="Newsimage flex w-52 h-52 rounded-lg"
                    src={item?.image_url}
                    alt="Image1"
                  />
                  <div className="flex mb-2">
                    <div>
                      <p className="flex font-bold">{item?.news_headline}</p>
                      <p>{item?.news_country}</p>
                      <p>{item?.news_city}</p>
                      <p>{item?.news_state}</p>
                      <p>{item?.news_description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}
export default Index;
