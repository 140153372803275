import React from "react";
import SupportImg from "../../Assets/Images/support1.jpg";
import Footer from "../../Layout/Footer";

function Support() {
  return (
    <div className="flex flex-col h-screen w-full">
      <div className="flex flex-row  items-center justify-center p-5">
        <div className="w-96">
          <img src={SupportImg} alt="support" />
        </div>
        <div className="flex flex-col">
          <div>
            <div>Name</div>
            <input className="p-2 border rounded-lg" placeholder="Enter Name" />
          </div>
          <div>
            <div>Mail id</div>
            <input
              className="p-2 border rounded-lg"
              placeholder="Enter Mail id"
            />
          </div>
          <div>
            <div>phone number</div>
            <input
              className="p-2 border rounded-lg"
              placeholder="Enter Phone number"
            />
          </div>
          <div>
            <div>Message</div>
            <textarea
              className="p-2 border rounded-lg"
              placeholder="Enter your message"
            />
          </div>
          <div className="flex justify-center">
            <div className="bg-blue-500 p-1 rounded-md text-white text-lg w-16">
              Submit
            </div>
          </div>
        </div>
      </div>
      <hr />
      <Footer />
    </div>
  );
}

export default Support;
