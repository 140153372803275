const API_URL = process.env.REACT_APP_API_URL;

const POST = async (path?: string, data?: any, token?: string) => {
  const res = await fetch(`${API_URL}${path}`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      const json = await res.json();
      console.log(json);
      return [res?.status, json];
    })
    .then((res) => {
      console.log("s", res);
      return res;
    })
    .catch((error) => {
      console.log(`error in post api ${path} >`, error);
      return [500, error];
    });
  return res;
};

const submitOtp = async (data: any) => {
  const path = "/submit-otp";
  return await POST(path, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("error on submit otp > ", err);
      return err;
    });
};

export { submitOtp };
