const API_URL = process.env.REACT_APP_API_URL;

export const POST = async (path?: string, data?: any, token?: string) => {
  const res = await fetch(`${API_URL}${path}`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data)
  })
    .then(async (res) => {
      const json = await res.json();
      console.log(json);
      return [res?.status, json];
    })
    .then((res) => {
      console.log("s", res);
      return res;
    })
    .catch((error) => {
      console.log(`error in post api ${path} >`, error);
      return [500, error];
    });
  return res;
};

const getOtp = async (data:any) => {
    const path = "/generate-otp";
    return await POST(path, data)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log("error on getProperties from db > ", err);
        return err;
      });
  };

  export {getOtp}