import React from "react";
import "./style.css";

function Index(data: any) {
  return (
    <div className="Grievfeed">
      {data?.data?.map((item: any) => {
        return (
          <>
            <div className="flex justify-center">
              <div className="Grievcard drop-shadow-lg">
                <div className="Grievcontent">
                  <img
                    className="Newsimage flex w-52 h-52 rounded-lg"
                    src={item?.image_url}
                    alt="Image1"
                  />
                  <div className="flex mb-2">
                    <div>
                      <p className="flex font-bold">
                        {item?.grievance_headline}
                      </p>
                      <p>{item?.grievance_country}</p>
                      <p>{item?.grievance_city}</p>
                      <p>{item?.grievance_state}</p>
                      <p>{item?.grievance_description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}
export default Index;
