import React from "react";
import "./style.css";
import "animate.css";

function Index() {
  return (
    <div>
      <div className="community animate__animated animate__backInDown animate__delay-1s">
        <p>Coming Soon...!</p>
      </div>
    </div>
  );
}

export default Index;
