import React from "react";
import Signup from "../../Assets/Icons/Signup.svg";
import Ads from "../../Assets/Icons/Ads.svg";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/Logopng.png";

function Index() {
  return (
    <div className="flex flex-row justify-between">
      <div className="p-3">
        <img src={Logo} alt="Logoside" className="w-40" />
      </div>
      <div className="flex flex-row px-2">
        <div className="flex flex-row items-center px-2">
          <img src={Signup} alt="Signup" />
          <Link to="/authpage">Signup</Link>
        </div>
        <div className="flex flex-row items-center px-2">
          <img src={Ads} alt="Ads" />
          <Link to="/">Ads</Link>
        </div>
      </div>
    </div>
  );
}

export default Index;
