import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import { cognito } from './Utils/App_Config/config';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,

    region: cognito?.REGION,

    userPoolId: cognito.USER_POOL_ID,

    userPoolWebClientId: cognito.APP_CLIENT_ID,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

