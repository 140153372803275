import { apiEndPoint } from "../../Constants";

export const post = async (path: any, data: any) => {
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      // console.log("res insdsada", res);
      console.log(`${apiEndPoint}/${path}`, "path for posts");
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in post api ${path} >`, error);
      return [550, error];
    });
  return res;
};

export const get = async (apiUri: any) => {
  const res = await fetch(`${apiEndPoint}${apiUri}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then(async (res) => {
      // console.log(t)
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => console.log(`error in api ${apiUri} >`, error));
  return res;
};

export const update = async (path: any, data: any) => {
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "PATCH",
    mode: "cors",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      console.log("res insdsada", res);
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in patch api ${path} >`, error);
      return [550, error];
    });
  return res;
};

// export const delete = async (apiUri, token) => {
export const deletes = async (apiUri: any) => {
  const res = await fetch(`${apiEndPoint}${apiUri}`, {
    method: "DELETE",
    mode: "cors",
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in delete ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};

export const uploadFiles = async (file: any,userData:any) => {
  const contentType = userData?.["content_type"];
  await fetch(`${apiEndPoint}/uploads`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
      ...userData,
    }),
  })
    .then((res) => res.json())
    .then((response) => {
      const bucketUrl = response.uploadURL;
      // console.log("upload response > ", response);
      const headers = new Headers();
      fetch(bucketUrl, {
        method: "PUT",
        body: file,
        headers: {
          ...headers,
          "Content-Type": contentType,
        },
      })
        .then((res) => {
          if (res.status === 200) {
             console.log("form submitted successfully",res.url);
             console.log("bucket url",response);
             return response;
          } else {
            alert("Failed");
            console.log("error while uploading file");
          }
          console.log(`success ${res.status} - ${res.statusText}`);
        })
        .catch((err) => {
          console.log(err);
          console.log(`error on bucket url ${err.status} - ${err.statusText}`);
        });
    })
    .catch((err) => console.log("error while fetching api endpoint", err));
};


