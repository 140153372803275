import React from "react";
import 'animate.css';

function Index() {
    return (
        <div className="flex justify-center items-center font-bold text-3xl h-96 animate__animated animate__backInDown">
   Coming Soon
        </div>


    );
}
export default Index;