import { get } from "../../../Utils/Functions/Methods/index";

const GetGrievancePost = async (user_id: any) => {
  try {
    const response: any = await get(`/grievancepost?user_id=${user_id}`); // assuming `get` is an async function
    return response[1]; // assuming the data you need is at index 1
  } catch (error) {
    console.error("Grievance fetch error", error);
    return null; // Return null or an appropriate value for error cases
  }
};
export default GetGrievancePost