import React, { useContext, useEffect, useState } from "react";
import Add from "../../Assets/Icons/Add.svg";
import Newsimage from "../../Assets/Images/Newsimage.svg";
import Like from "../../Assets/Icons/Like.svg";
import Dislike from "../../Assets/Icons/Dislike.svg";
import Share from "../../Assets/Icons/Share.svg";
import closeicon1 from "../../Assets/Icons/close-icon1.png";
import { Button, Form, Modal } from "react-bootstrap";
import { get, post, uploadFiles } from "../../Utils/Functions/Methods";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import "./style.css";
import Report from "../../Layout/Report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import {
  getCitiesByStateAndCountry,
  getCountries,
  getStatesByCountry,
} from "../../Utils/countryApi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BeatLoader, PulseLoader } from "react-spinners";
import apis from "../../api";
import { blueGrey, green } from "@mui/material/colors";

type State = {
  map(arg0: (state: any) => JSX.Element): React.ReactNode;
  id: number;
  name: string;
  iso2: string;
};
type City = {
  map(arg0: (state: any) => JSX.Element): React.ReactNode;
  id: number;
  name: string;
  iso2: string;
};

function Index() {
  const [files, setFiles] = useState<any>("");
  const [RegisterModal, setRegisterModal] = useState(false);
  const handleClose = () => setRegisterModal(false);
  const auth = useContext<any>(AuthContext);
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const newsFilePath = "newspost/";
  const initialValue = {
    headline: "",
    posted_by: auth?.userInfo[0]?.user_id,
    category: "",
    description: "",
    state: "",
    city: "",
    country: "",
    category_id: 1,
    anonymous_user: null,
    f_name: "",
    f_path: newsFilePath,
    f_type: "",
    f_size: "",
  };

  // console.log("ERROR ID", auth?.userInfo[0]?.user_id);
  const schema = yup.object().shape({
    headline: yup

      .string()

      .required("Headline is Required")

      .min(5, "Min. 5 char Required"),

    category: yup

      .string()

      .required("Select Category")

      .min(5, "Min. 5 char Required"),

    description: yup
      .string()

      .required("Description is Required")

      .min(20, "min. 20 char Required"),

    country: yup

      .string()

      .required("Select District")

      .min(5, "Min. 5 char Required"),

    state: yup

      .string()

      .required("Select State")

      .min(5, "Min. 5 char Required"),

    city: yup

      .string()

      .required("Select State")

      .min(5, "Min. 5 char Required"),

    anonymous_user: yup

      .string()

      .required("Select any one")

      .min(2, "Min. 2 char Required"),
  });
  const {
    register: register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const [newsDetails, setNewsDetails] = useState(initialValue);
  const [states, setStates] = useState<State | null>(null);
  const [cities, setCities] = useState<City[]>([]);
  const [countries, setCountries] = useState<string[] | null>(null);
  const [getNews, setgetNews] = useState<any>("");
  const [getNewsCategory, setGetNewsCategory] = useState("");

  // console.log(getNews, "getNews");
  const handleCountryChange = (e: any) => {
    const iso2 = e.target.value.split("-")[1];
    setNewsDetails({ ...newsDetails, country: e.target.value });
    getStatesByCountry(iso2).then((e) => {
      setStates(e);
    });
  };
  const handleStateChange = (e: any) => {
    const iso2 = e.target.value.split("-")[1];
    const countryIso = newsDetails.country.split("-")[1];
    setNewsDetails({ ...newsDetails, state: e.target.value });

    getCitiesByStateAndCountry(countryIso, iso2).then((e) => setCities(e));
  };

  const handleCityChange = (e: any) => {
    setNewsDetails({ ...newsDetails, city: e.target.value });
  };

  useEffect(() => {
    getCountries().then((e) => setCountries(e));
  }, []);

  const onNewsDetails = (key: any) => (value: any) => {
    setNewsDetails({ ...newsDetails, [key]: value });
  };

  const [base64String, setBase64String] = useState("");

  const handleFileChange = (files: any) => {
    const file = files[0];
    // const file = event.target.files[0];
    // setFile(file);
    // console.log("file", file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = String(reader.result).split(",")[1];
      setBase64String(base64String);
      setFiles(file);
    };
  };
  const [loading, setLoading] = useState(false);
  const newsSubmit = async () => {
    setLoading(true);
    if (files === "") {
      setLoading(false);
      alert("empty");
    } else {
      const fileData = {
        file_name: files["name"].split(".")[0],
        content_type: files["type"],
        path: newsFilePath,
        extension: files["name"].split(".").pop(),
      };

      setData(fileData);

      await uploadFiles(files, fileData)
        .then(async () => {
          const { file_name, content_type, path, extension } = fileData;
          // console.log("Temp value----", fileData);
          // console.log("s3 response");
          const temp = {
            ...newsDetails,
            ...fileData,
            f_name: file_name,
            f_type: content_type,
            f_path: path,
            f_extension: extension,
            img_base64: base64String,
          };

          // console.log("Temp", temp);

          await post(`/newsPost`, temp).then(([status, res]) => {
            // console.log("......", res);
            if (status === 401) {
              setLoading(false);
              // console.log("code", data, status, res);
              alert("Contains bad words");

              // console.log("result fail", res, status);
            } else if (status === 200) {
              setLoading(false);
              setFiles(null);
              setNewsDetails(initialValue);
              alert("News Added Successfully");
              setRefresh(!refresh);
              setRegisterModal(false);
              fetchNews();
              reset();
            } else {
              setLoading(false);
              alert("Unexpected error occurred, Please try again");
              setNewsDetails(initialValue);
              reset();
            }
          });
        })
        .catch((err) => console.log(err));
    }
  };

  console.log("Auth>>>>>>>>>>>>>>>>>>", auth?.userInfo[0]?.user_id);
  const fetchNews = async () => {
    try {
      const res = await apis.GetNews(auth?.userInfo[0]?.user_id);
      setgetNews(res);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };
  const newsCategory = async () => {
    try {
      const response = await apis.GetNewsCategory();
      setGetNewsCategory(response);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      // You can add additional error handling here if needed
    }
  };

  useEffect(() => {
    setIsLoading(true);
    newsCategory();
    fetchNews();
  }, []);

  async function handleLikeClick(newsId: any) {
    const temp = {};

    try {
      const response = await apis.PostNewLikes(
        auth?.userInfo[0]?.user_id,
        newsId,
        temp
      );

      // console.log(response);
    } catch (error) {
      console.log("error", error);
      // You can add additional error handling here if needed
    }
    fetchNews();
  }

  async function handleDislikeClick(newsId: any) {
    const temp = {};

    try {
      const response = await apis.PostNewDisLikes(
        auth?.userInfo[0]?.user_id,
        newsId,
        temp
      );
      // console.log(response);
    } catch (error) {
      console.log("error", error);
      // You can add additional error handling here if needed
    }
    fetchNews();
  }
  const navigate = useNavigate();

  return auth?.isAuthenticated ? (
    <div className="newsfeed-container">
      <div className="flex flex-col">
        {/* <NewsCategory /> */}
        <div className="Navspace">
          <div>
            {auth?.userInfo[0]?.is_verified ? (
              <div
                className="Addcontent"
                onClick={() => setRegisterModal(true)}
              >
                <div className="flex gap-1">
                  <p>Add your Content</p>
                  <img className="Addimage" src={Add} alt="Add" />
                </div>
              </div>
            ) : (
              <div
                className="Addcontent"
                onClick={() => navigate("../Profile")}
              >
                verify with Aadhar to add news
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="Newsfeed">
        {!isLoading && <p>No items found</p> && getNews?.length == 0}
        {isLoading && (
          <PulseLoader margin={10} className="flex justify-center" />
        )}
        {!isLoading &&
          getNews?.length > 0 &&
          Array.isArray(getNews) &&
          getNews?.map((lpdata, idx) => {
            console.log(lpdata, "???????????????????????????");
            return (
              <div className="flex justify-center">
                <div className="Newscard drop-shadow-lg">
                  <div className="Newscontent">
                    {/* <img
                      className="Newsimage flex w-52 h-52 rounded-lg"
                      src={lpdata?.image_url}
                      alt="Newsimage"
                    /> */}
                    {lpdata?.file_extension === "mp4" ? (
                      <video width="250" height="250" controls>
                        <source src={lpdata?.image_url} />
                      </video>
                    ) : (
                      <img
                        className="Newsimage flex w-52 h-52 rounded-lg"
                        src={lpdata?.image_url}
                        alt="Newsimage"
                      />
                    )}
                    <div>
                      <div className="flex mb-2">
                        <div className="flex">
                          <div>
                            <div className="userName">{lpdata?.name}</div>
                            <div className="city">{lpdata?.news_state} </div>
                          </div>
                          <div className="flex">
                            <div className="time ml-3">
                              {lpdata?.file_added_time &&
                                lpdata.file_added_time.slice(0, 10)}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="Headline">{lpdata?.news_headline}</div>

                      <div className="Description">
                        {lpdata?.news_description}
                        <div className="flex mt-3">
                          <div>
                            <div className="flex gap-5">
                              <div>
                                <FontAwesomeIcon
                                  className={`${
                                    lpdata?.is_liked
                                      ? "text-blue-500"
                                      : "text-gray-500"
                                  }`}
                                  icon={faThumbsUp}
                                  onClick={() => {
                                    handleLikeClick(lpdata?.news_id);
                                  }}
                                />
                                <p>{lpdata?.like_count}</p>
                              </div>
                              <div>
                                <FontAwesomeIcon
                                  className={`${
                                    lpdata?.is_disliked
                                      ? "text-red-500"
                                      : "text-gray-500"
                                  }`}
                                  icon={faThumbsDown}
                                  onClick={() => {
                                    handleDislikeClick(lpdata?.news_id);
                                  }}
                                  style={
                                    {
                                      // color: dislikeColor[lpdata?.news_id] ? 'red' : 'gray',
                                      // height: 25,
                                    }
                                  }
                                />
                                <p>{lpdata?.dislike_count}</p>
                              </div>
                              <div className="flex gap-1">
                                <Report id={lpdata?.news_id} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Modal
        className="newsregister-modal"
        show={RegisterModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">News Register</Modal.Title>
          <img
            className="right-3 mt-2 sm:right-10 md:right-5 absolute hover:scale-110 transition-transform duration-300"
            src={closeicon1}
            onClick={() => setRegisterModal(false)}
          ></img>
        </Modal.Header>
        <Modal.Body className="modalbody">Type News Details Here</Modal.Body>
        <Modal.Body className="text-center">
          <div>
            <input
              placeholder="Headlines"
              className="modalHeadline w-full p-2 text-base border rounded-lg"
              value={newsDetails?.headline}
              {...register("headline")}
              onChange={(e) => {
                onNewsDetails("headline")(e.target?.value);
              }}
            />
            <div>
              <span className="text-danger">
                {errors.headline && errors.headline.message?.toString()}
              </span>
            </div>
          </div>

          <br />

          <select
            id="Category"
            className="modalcategory w-full p-2 text-base border rounded-lg"
            placeholder="Category"
            onChange={(_) => {
              onNewsDetails("category")(_?.target?.value);
            }}
          >
            {Array.isArray(getNewsCategory) &&
              getNewsCategory.map((categoryData: any, idx: any) => (
                <option key={idx} value={categoryData.value}>
                  {categoryData?.category_name}
                </option>
              ))}
          </select>

          <br />
          <br />
          <div>
            <textarea
              placeholder="Type News Description..."
              className="modaldescription p-2.5 w-full text-base rounded-lg border"
              value={newsDetails?.description}
              {...register("description")}
              onChange={(e) => {
                onNewsDetails("description")(e.target?.value);
              }}
            />
            <div>
              <span className="text-danger">
                {errors.description && errors.description.message?.toString()}
              </span>
            </div>
          </div>

          <br />
          <input
            onChange={(_) => handleFileChange(_.target.files)}
            type="file"
            className="modalfile pl-10 "
            id="formFile"
            name="files"
          />
          <p className="mt-2 text-sm text-slate-400">
            **Supported formats: Upload only jpg/png/mp4 files**
          </p>
          <br />
          <br />
          <select
            placeholder="Country"
            className="modalplace w-full p-2 text-base text-gray-500 border rounded-lg"
            onChange={handleCountryChange}
            defaultValue={newsDetails?.country}
          >
            <option disabled value="">
              --Select Country--
            </option>
            {countries
              ? countries.map((country: any) => (
                  <option
                    key={country.id}
                    value={`${country.name}-${country.iso2}`}
                    selected={
                      `${country.name}-${country.iso2}` == newsDetails.country
                    }
                  >
                    {country.name}
                  </option>
                ))
              : ""}
          </select>
          <br />
          <br />
          <select
            id="State"
            className="modalplace w-full p-2 text-base text-gray-500 border rounded-lg"
            onChange={handleStateChange}
            defaultValue={newsDetails?.state}
          >
            <option selected disabled value="">
              --Select State--
            </option>
            {states
              ? states.map((state: any) => (
                  <option
                    key={state.id}
                    value={`${state.name}-${state.iso2}`}
                    selected={
                      `${state.name}-${state.iso2}` == newsDetails.state
                    }
                  >
                    {state.name}
                  </option>
                ))
              : ""}
          </select>
          <select
            id="city"
            className="modalplace w-full p-2 mt-3 text-base text-gray-500 border rounded-lg"
            onChange={handleCityChange}
            defaultValue={newsDetails?.city}
          >
            <option selected disabled value="">
              --Select City--
            </option>
            {cities
              ? cities.map((city) => (
                  <option
                    key={city?.id}
                    value={`${city?.name}-${city?.iso2}`}
                    selected={`${city?.name}-${city?.iso2}` == newsDetails.city}
                  >
                    {city?.name}
                  </option>
                ))
              : ""}
          </select>
          <select
            id="Anonymous"
            className="modalplace w-full p-2 mt-3 text-base border rounded-lg"
            onChange={(_) => {
              onNewsDetails("anonymous_user")(_?.target?.value);
            }}
          >
            <option selected>Share as Anonymous User</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
          <br />
        </Modal.Body>
        <Modal.Footer className="modalButton">
          {loading ? (
            <div className="flex justify-center">
              <BeatLoader />
            </div>
          ) : (
            <button
              type="button"
              className=" text-white bg-blue-700 hover:bg-blue-800  
        font-bold rounded-md h-10 w-24 text-lg"
              onClick={() => newsSubmit()}
            >
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  ) : null;
}
export default Index;
