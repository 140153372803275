import React from "react";
import "./style.css";
import NavBar from "../../../NavBar";
import Footer from "../..";

function Index() {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="py-5">
        <h2 className="flex items-center justify-center text-3xl font-semibold">
          About Us
        </h2>
        <div
          className="border-2 shadow-lg rounded-md m-5  h-[450px] "
          style={{ overflow: "scroll", overflowX: "hidden" }}
        >
          <p className="py-2 px-4">
            Welcome to our JGR News and Grievance Media Platform! We are a
            dedicated platform that aims to provide a comprehensive space for
            sharing news, expressing grievances, and facilitating access to
            government grievance resolution resources. Our platform empowers
            individuals to raise their voices, bring attention to important
            issues, and seek resolution while fostering a sense of community and
            accountability.
          </p>
          <div>
            <p className="flex items-center px-4 text-lg font-semibold">
              Our Mission
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              Amplifying Voices: We believe in the power of free expression and
              aim to amplify the voices of individuals by providing a platform
              where they can share their news, grievances, and experiences with
              a broader audience.
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              Raising Awareness: We strive to raise awareness about social,
              political, and community issues by encouraging the responsible
              sharing of news and facilitating discussions that foster
              understanding and empathy.
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              Promoting Change: We are committed to facilitating positive change
              by empowering individuals to voice their concerns, share their
              stories, and advocate for improvements in governance, policies,
              and social issues.
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              Ensuring Accountability: We aim to promote accountability by
              providing reliable information and resources, enabling users to
              access official government grievance resolution websites and
              channels.
            </p>
          </div>
          <div>
            <p className="flex items-center px-4 text-lg font-semibold">
              Key Features:
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              News Sharing: Our platform enables users to share news articles,
              stories, and updates on a wide range of topics. We encourage
              responsible journalism and emphasize the importance of verifying
              sources and fact-checking before sharing information.
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              Grievance Expression: We provide a dedicated space for individuals
              to express their grievances, share personal experiences, and seek
              support. By providing this outlet, we aim to raise awareness about
              societal issues, foster dialogue, and encourage positive change.
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              Government Grievance Resolution Links: We understand the
              importance of connecting users with appropriate channels to
              address their grievances effectively. We provide direct links to
              official government grievance resolution websites and resources,
              ensuring users have access to the appropriate channels for seeking
              resolution.
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              Aadhaar Verification: To maintain the authenticity and credibility
              of the content on our platform, we require users to verify their
              identity using their Aadhaar card. This verification process helps
              ensure a trusted and responsible community of users.
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              User-Generated Content: We encourage users to actively participate
              by uploading their news articles and grievances. By sharing their
              stories, experiences, and concerns, users contribute to creating
              an engaged and informative community.
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              Moderation and Quality Control: We have a dedicated team that
              moderates user-generated content to ensure compliance with
              community guidelines. This helps maintain a respectful, inclusive,
              and safe environment for all users.
            </p>
            <p className="px-4 py-2">
              <span className="inline-block mr-2 text-xl">&#8226;</span>
              Community Engagement: We foster a sense of community by
              encouraging users to engage in meaningful discussions, share
              perspectives, and provide constructive feedback. Through active
              participation, users contribute to building a supportive and
              collaborative community.
            </p>
          </div>
          <div>
            <p className="px-4 py-2">
              Join us in making a difference! Together, we can create an
              informed society, raise awareness about critical issues, and work
              towards positive change. We value your participation and encourage
              you to upload your news, express your grievances, and engage with
              our community.
            </p>
            <p className="px-4 py-2">
              For any questions, feedback, or concerns, please feel free to
              reach out to our support team. We are here to assist you and
              continuously improve our platform to meet your needs.
            </p>
          </div>

          <div className="py-2">
            <p className="flex items-center justify-center px-4 text-lg font-semibold">
              Thank you for being a part of our News and Grievance Media
              Platform!
            </p>
          </div>
        </div>
        {/* <div className="h-0.5 w-full bg-indigo-400"></div> */}
        <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>

        <div className="py-2">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Index;
