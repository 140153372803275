import React, { useContext, useEffect, useState } from "react";
import Profileimage from "../../Assets/Images/Profileimage.svg";
import Call from "../../Assets/Icons/Call.svg";
import Email from "../../Assets/Icons/Email.svg";
import LocationPro from "../../Assets/Icons/Locationpro.svg";
import Saved from "../../Assets/Icons/Saved.svg";
import News from "../../Assets/Icons/News.svg";
import Grievance from "../../Assets/Icons/Grievance.svg";
import GrievanceSec from "./Component/GrievanceSec";
import NewsSec from "./Component/NewsSec";
import SavedSec from "./Component/SavedSec";
import closeicon1 from "../../Assets/Icons/close-icon1.png";
import "./style.css";
import BlueNews from "../../Assets/Icons/Newsblue.svg";
import BlueGriev from "../../Assets/Icons/Grievblue.svg";
import Bluesave from "../../Assets/Icons/Saveblue.svg";
import { Modal } from "react-bootstrap";
import { get, update } from "../../Utils/Functions/Methods";
import { AuthContext } from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import {
  getCitiesByStateAndCountry,
  getCountries,
  getStatesByCountry,
} from "../../Utils/countryApi";
import { useForm } from "react-hook-form";
import { BeatLoader, PulseLoader } from "react-spinners";
import apis from "../../api";

type State = {
  map(arg0: (state: any) => JSX.Element): React.ReactNode;
  id: number;
  name: string;
  iso2: string;
};
type City = {
  map(arg0: (state: any) => JSX.Element): React.ReactNode;
  id: number;
  name: string;
  iso2: string;
};

function Index() {
  const [registerModal, setRegisterModal] = useState(false);
  const handleClose = () => setRegisterModal(false);
  const [changeDetails, setChangeDetails] = useState("NewsSec");
  const auth = useContext<any>(AuthContext);
  const [profile, setProfile] = useState<any>("");
  const [newsUploadDetails, setNewsUploadDetails] = useState<any>("");

  const { userAttributes }: any = useContext(AuthContext);
  const [updateData, setUpdateData] = useState<any>();

  const [editProfile, setEditProfile] = useState<any>("");

  const [states, setStates] = useState<State | null>(null);
  const [cities, setCities] = useState<City | null>(null);
  const [countries, setCountries] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleCountryChange = (e: any) => {
    const iso2 = e.target.value.split("-")[1];
    setEditProfile({ ...editProfile, country: e.target.value });
    getStatesByCountry(iso2).then((e) => {
      setStates(e);
    });
  };
  const handleStateChange = (e: any) => {
    const iso2 = e.target.value.split("-")[1];
    const countryIso = editProfile.country.split("-")[1];
    setEditProfile({ ...editProfile, state: e.target.value });
    getCitiesByStateAndCountry(countryIso, iso2).then((e) => setCities(e));
  };

  useEffect(() => {
    getCountries().then((e) => setCountries(e));
  }, []);

  async function newsGrievanceUpload() {
    const userId = auth?.userInfo[0]?.user_id;
    if (userId) {
      const uploads = await apis.GetUserNewsUploads(userId);
      if (uploads) {
        setNewsUploadDetails(uploads);
      }
    }
    setIsLoading(false);
  }

  async function profileDetails() {
    // Assuming you have access to the user's attributes through a context or a state
    const userId = userAttributes?.sub;
    if (userId) {
      const userProfile = await apis.GetUserProfile(userId);
      if (userProfile) {
        setProfile(userProfile);
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    newsGrievanceUpload();
    profileDetails();
  }, []);

  console.log("details", newsUploadDetails?.[0]?.newsUpload);

  const switchDetails = () => {
    switch (changeDetails) {
      case "NewsSec":
        return <NewsSec data={newsUploadDetails?.[0]?.newsUpload} />;
      // return <NewsSec/>;
      case "GrievanceSec":
        return <GrievanceSec data={newsUploadDetails?.[0]?.grievance_upload} />;
      case "SavedSec":
        return <SavedSec />;
    }
  };

  function updateModal(data: any) {
    setRegisterModal(true);
    setUpdateData(data);
  }
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const submitProfileDetails = async (e: any) => {
    setLoading(true);
    console.log("value e-------", e);
    await update(`/users/${updateData?.user_id}`, { ...e })
      .then(([status, res]) => {
        if (status === 200) {
          alert("Update Successful");
          setLoading(false);
          setRegisterModal(false);
          console.log("result success", res);
          setUpdateData(res);
          profileDetails();
        } else {
          setLoading(false);
          alert("Something went wrong");

          console.log("result fail", res);
        }
      })

      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onSubmit = async (event: any) => {
    // console.log(event);
    await submitProfileDetails(event);
    // console.log("clicked");
  };

  if (!newsUploadDetails || !profile) {
    // Data is still loading, show a loading indicator or return null
    return <div className="loading">Loading...</div>;
  }

  return (
    <div>
      <div className="flex Profilepage">
        <div className="Leftbox">
          <img src={Profileimage} alt="Profileimage" />
          {!isLoading && <p>No items found</p> && profile?.length == 0}
          {isLoading && (
            <PulseLoader margin={10} className="flex justify-center" />
          )}
          {!isLoading &&
            profile?.length > 0 &&
            Array.isArray(profile) &&
            profile?.map((Prodata, idx) => {
              return (
                <div className="flex flex-col gap-8">
                  <div className="flex justify-center">
                    <p className="flex font-bold text-xl">
                      {Prodata?.first_name}
                    </p>
                  </div>

                  <div className="flex flex-col gap-5 items-start sm:items-start ">
                    <div className="flex gap-3">
                      <img className="flex w-6" src={Call} alt="Call" />
                      <p className="flex font-semibold text-base">
                        {Prodata?.mobileno}
                      </p>
                    </div>
                    <div className="flex gap-3">
                      <img className="flex w-6" src={Email} alt="Email" />
                      <p className="flex font-semibold text-base">
                        {Prodata?.email}
                      </p>
                    </div>
                    <div className="flex gap-3">
                      <img
                        className="flex w-6"
                        src={LocationPro}
                        alt="Location"
                      />
                      <p className="flex font-semibold text-base">
                        {Prodata?.city.split("-")[0]}
                      </p>
                    </div>
                  </div>

                  {auth?.userInfo[0]?.is_verified ? (
                    <></>
                  ) : (
                    <div className="flex justify-center mt-1">
                      <div className="Button text-white bg-blue-700 rounded-md">
                        <Link to="/verification" className=" text-white">
                          <p>Verify Aadhar</p>
                        </Link>
                      </div>
                    </div>
                  )}

                  <div className="flex justify-center mt-1">
                    <button
                      type="button"
                      className="Button  text-white bg-blue-700 hover:bg-blue-800 rounded-md"
                      onClick={() => updateModal(Prodata)}
                    >
                      Edit Profile
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="flex flex-col gap-5 w-2/3 mt-16 profile-post-content">
          <div className=" Uploads flex justify-between">
            <div
              className={`flex ${
                changeDetails === "NewsSec" ? "highlighted" : ""
              }`}
              onClick={() => setChangeDetails("NewsSec")}
            >
              <img
                src={changeDetails === "NewsSec" ? BlueNews : News}
                alt="News"
                className={`news-image ${
                  changeDetails === "NewsSec" ? "selected" : ""
                }`}
              />
              <p className="flex text-xl">Newsuploads</p>
            </div>
            <div
              className={`flex ${
                changeDetails === "GrievanceSec" ? "highlighted" : ""
              }`}
              onClick={() => setChangeDetails("GrievanceSec")}
            >
              <img
                src={changeDetails === "GrievanceSec" ? BlueGriev : Grievance}
                alt="Grievance"
              />
              <p className="flex text-xl">Grievanceuploads</p>
            </div>
            <div
              className={`flex ${
                changeDetails === "SavedSec" ? "highlighted" : ""
              }`}
              onClick={() => setChangeDetails("SavedSec")}
            >
              <img
                src={changeDetails === "SavedSec" ? Bluesave : Saved}
                alt="Saved"
              />
              <p className="flex  text-xl">Saved</p>
            </div>
          </div>
          <div>{switchDetails()}</div>
        </div>
      </div>

      <Modal className="" show={registerModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
          <img
            className="right-3 mt-2 sm:right-10 md:right-5 absolute hover:scale-110 transition-transform duration-300"
            src={closeicon1}
            onClick={() => setRegisterModal(false)}
          ></img>
        </Modal.Header>
        <Modal.Body className="text-center text-lg font-bold">
          Change Profile Details
          <div className="flex justify-center mt-2">
            <img className="flex w-32" src={Profileimage} alt="profilephoto" />
          </div>
        </Modal.Body>
        <Modal.Body className="text-center">
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              className="form-control"
              placeholder="First name"
              defaultValue={updateData?.first_name}
              {...register("first_name", { required: true })}
            />
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="Last name"
              defaultValue={updateData?.last_name}
              {...register("last_name", { required: true })}
            />
            <br />
            <input
              type="text"
              className="form-control"
              placeholder="Mobile number"
              defaultValue={updateData?.mobileno}
              {...register("mobileno", { required: true })}
            />

            <br />

            <input
              disabled
              type="text"
              className="form-control"
              placeholder="Email"
              defaultValue={updateData?.email}
              // {...register("email", { required: true })}
              // style={{ cursor: "not-allowed" }}
            />

            <br />
            <select
              placeholder="Country"
              className="w-full p-2 text-base text-gray-500 border rounded-lg"
              onChange={handleCountryChange}
              defaultValue={updateData?.country}
            >
              <option disabled value="">
                --Select country --
              </option>
              {countries
                ? countries.map((country: any) => (
                    <option
                      key={country.id}
                      value={`${country.name}-${country.iso2}`}
                      selected={
                        `${country.name}-${country.iso2}` == editProfile.country
                      }
                    >
                      {country.name}
                    </option>
                  ))
                : ""}
            </select>
            <br />
            <br />
            <select
              id="State"
              className="w-full p-2 text-base text-gray-500 border rounded-lg"
              onChange={handleStateChange}
              defaultValue={updateData?.state}
            >
              <option selected disabled value="">
                --Select state --
              </option>
              {states
                ? states.map((state: any) => (
                    <option
                      key={state.id}
                      value={`${state.name}-${state.iso2}`}
                      selected={
                        `${state.name}-${state.iso2}` == editProfile.state
                      }
                    >
                      {state.name}
                    </option>
                  ))
                : ""}
            </select>
            <select
              id="city"
              className="w-full p-2 mt-3 text-base text-gray-500 border rounded-lg"
              defaultValue={updateData?.city}
              {...register("city", { required: true })}
            >
              <option selected disabled value="">
                --Select City --
              </option>
              {cities
                ? cities.map((city) => (
                    <option
                      key={city?.id}
                      value={`${city?.name}-${city?.iso2}`}
                      selected={
                        `${city?.name}-${city?.iso2}` == editProfile.city
                      }
                    >
                      {city?.name}
                    </option>
                  ))
                : ""}
            </select>
            <Modal.Footer>
              {loading ? (
                <div className="flex justify-center">
                  <BeatLoader />
                </div>
              ) : (
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 
            font-bold rounded-md h-10 w-24 text-lg  dark:bg-blue-600 dark:hover:bg-blue-700 
            focus:outline-none dark:focus:ring-blue-800"
                  >
                    Submit
                  </button>
                </div>
              )}
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Index;
