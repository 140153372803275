import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LoginPage from "../Pages/Auth/AllLogin";
import AuthPage from "../Pages/Auth/MainPage";
import NewsfeedPage from "../Pages/NewsFeed";
import GrievancefeedPage from "../Pages/GrievanceFeed";
import JGRCommunity from "../Pages/JGRCommunity";
import Directory from "../Pages/Directory";
import Profile from "../Pages/Profile";
import GetOtp from "../Pages/AadharVerify/GetOtp/index";
import Layout from "../Layout/loginLayout/index";
import ForgetPassword from "../Pages/ForgetPassword";
import Privacy from "../Layout/Footer/Components/PrivacyPolicy";
import About from "../Layout/Footer/Components/AboutUs";
import Support from "../Pages/Support/Support";

export default function Index() {
  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/LoginPage" element={<LandingPage />} /> */}
          <Route path="privacypolicy" element={<Privacy />} />
          <Route path="aboutus" element={<About />} />
          <Route path="support" element={<Support />} />
          <Route path="/authpage" element={<AuthPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="verification" element={<GetOtp />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<NewsfeedPage />} />
            <Route path="GrievancefeedPage" element={<GrievancefeedPage />} />
            <Route path="JGRCommunity" element={<JGRCommunity />} />
            <Route path="Directory" element={<Directory />} />
            <Route path="Profile" element={<Profile />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
