import { get } from "../../../Utils/Functions/Methods/index";

const GetAllStateDirectory = async () => {
  try {
    const response: any = await get("/govtDirectory");
    return response[1]; // Return the response data
  } catch (error) {
    console.log("error", error);
    return null; // Return null or an appropriate value for error cases
  }
};

export default GetAllStateDirectory;