import {get} from "../../../Utils/Functions/Methods/index";

const GetNewsCategory = async () => {
  try {
   const response:any = await get("/newsPost/category");
    return response[1]; // Return the response data
  } catch (error) {
    console.log("error", error);
    return null; // Return null or an appropriate value for error cases
  }
};

export default GetNewsCategory