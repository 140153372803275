import React from "react";
import NavBar from "../../../NavBar";
import Footer from "../..";
import "./style.css";

function Index() {
  return (
    <>
      <NavBar />
      <div className="py-5">
        <h2 className="flex items-center justify-center text-3xl font-semibold">
          Privacy Policy
        </h2>
        <div
          className="border-2 shadow-lg rounded-md m-5  h-[450px] "
          style={{ overflow: "scroll", overflowX: "hidden" }}
        >
          <p className="py-2 px-4">
            Please read this Privacy Policy very carefully (along with the Terms
            of Service and Community Guidelines together called the “Terms”).
            This contains important information about the privacy of Your
            information and data with respect to the use of JGR Platforms and
            services connected with it. This Privacy Policy sets out the manner
            in which XBI4 Private Limited, having its place of consulting
            business at 466/1 Panpoli to Shencottah Main Road; Panpoli;
            Shencottah; Tirunelveli; Tamil Nadu; 627809; India. (“XBI4”, or
            “We”, or “Us” or “JGR”), collects, uses, maintains, and discloses
            information collected from the users of mobile or desktop
            applications and/or services connected with it (“You” or “Your” or
            “User”).
            <p className="py-3">
              This Privacy Policy applies to JGR mobile or desktop application
              software technology (“Platform” or “Services” or “JGR”), and
              services offered by XBI4 in connection with the Platform. By using
              or registering Your profile with the Platform and/or by using
              Services offered in connection with the Platform, you are
              consenting to the use, collection, transfer, storage, disclosure,
              and other uses of Your information in the manner set out in this
              Privacy policy. For the purpose of this policy, any reference to
              XBI4 shall include its business associate(s), affiliates,
              subsidiaries, and concerns.
            </p>
            <p className="py-2">
              This document is published in accordance with the provisions of
              the Information Technology Act of 2000 read along with its
              relevant rules including Information Technology (Reasonable
              Security Practices and Procedures and Sensitive Personal Data or
              Information) Rules, 2011, and Information Technology (Intermediary
              Guidelines and Digital Media Ethics Code) Rules, 2021 and
              amendments if any.
            </p>
            <p className="py-2">
              We are committed to protecting your privacy. This Policy explains
              our practices concerning the personal data we collect from you, or
              that you provide to us. If you do not agree with this policy, you
              should not use the Platform.
            </p>
            <p>
              This Privacy Policy does not apply to the practices of third
              parties that Eterna does not own, control, or manage including but
              not limited to any third-party websites, services, applications,
              or businesses (“Third-Party Services”). Eterna does not take
              responsibility for the content or privacy policies of those
              Third-Party Services. Eterna encourages You to carefully review
              the privacy policies of all Third-Party Services You access
              through the Platform.
            </p>
            <p className="py-2">
              If You do not agree with any of the provisions of this Privacy
              Policy, you may not download, install and/or use the Platform
              and/or Services. Eterna may revise, alter, add, amend, or modify
              this Privacy Policy at any time by updating this page. By
              updating, downloading, installing, and/or continuing to access/use
              this Platform, you agree to be bound by any such alteration,
              amendment, addition, or modification.
            </p>
          </p>
          <div>
            <p className="flex items-center px-4 text-lg font-semibold">
              Privacy of Users
            </p>
            <p className="px-4 py-2">
              XBI4 takes the privacy of its users seriously and exercises its
              best efforts to protect their private data/information. XBI4
              endeavours to provide a secure and reliable environment for using
              its Platform and Services. Any private information collected by
              XBI4 from You will be used only in accordance with this Privacy
              Policy and the terms and conditions provided hereunder.
            </p>
            <p className="px-4 py-2">
              This Privacy Policy sets out what information XBI4 collects from
              You, how that information may be used, how the information will be
              protected, and what choices You have as a user. You are advised to
              read the policy carefully and provide Your consent only if You
              agree with the terms of the Policy.
            </p>
          </div>
          <div>
            <p className="flex items-center px-4 text-lg font-semibold">
              What information may be collected by Eterna?
            </p>
            <p className="px-4 py-2">
              XBI4 collects information to provide better services to all our
              Users – from figuring out basic stuff such as which language you
              prefer to more complex things like which ads, you’ll find most
              useful, the information matters the most to You online. The
              information Eterna collects, and how that information is used,
              depends on how You use our Services and how you manage Your
              privacy controls.{" "}
            </p>
            <p className="px-4 py-2">
              XBI4 may collect information from Users at various stages from
              registration on the Platform by the Users to submission of content
              by the Users or while the User is accessing/using Services. The
              information collected may be personally identifiable information
              or other information. You may choose to not provide the
              information if You do not wish to use the Platform and/or
              Services.
            </p>
          </div>
          <div>
            <p className="flex items-center px-4 text-lg font-semibold">
              Personally Identifiable Information
            </p>
            <p className="px-4 py-2">
              XBI4 may collect personal information that identifies You in a
              variety of ways, including, but not limited to the information
              submitted during download, installation, and/or use/access of the
              Platform or in connection with access/use of Services or features
              offered through the Platform or in connection with the Platform.
              "Personal information" of a person means any information which,
              either directly or indirectly, in combination with other
              information available or likely to be available is capable of
              identifying such a person.{" "}
            </p>
            <p className="px-4 py-2">
              When You create/register Your account, you provide us with
              personal information that includes your First and last name, email
              phone number, city, district, state, Pin code, and Aadhar number.
              You can also choose to add a phone number or any other information
              to your account. If You signed into an account, you might choose
              to provide us with information — like an email address to receive
              updates about our Services. The journalist who already work in any
              other media and uses our app to post news need to fill in
              Designation, Language is known, Experience, no of companies,
              Company name, Company City, Company state, Company year,
              Qualification, Kind of medium (News channel or paper), Published
              Journals.
            </p>
            <p className="px-4 py-2">
              XBI4 collects Personally Identifiable Information only if You
              voluntarily submit such information to us or provide access to
              such information by connecting to social networking sites through
              the Platform or Services (for example when You use the Services
              connected with the Platform or engage in certain Platform related
              activities including but not limited to becoming a member or
              signing up for an account or linking an account through a social
              network including, but not limited to Facebook, Twitter, Google
              Plus, and/or LinkedIn). XBI4 may also collect personally
              identifiable information You may provide when corresponding with
              us by email or phone, or to access/use any other
              interactive/non-interactive features of the Platform and/or
              Services. You can always refuse to supply personally identifiable
              information, except that it may prevent You from using the
              Platform, and/or certain features of the Platform and Services.
            </p>
          </div>
          <div>
            <p className="flex items-center px-4 text-lg font-semibold">
              Information about our company
            </p>
            <p className="px-4 py-2">
              People can learn more about the issues and worries in their area
              by visiting the JGR website or mobile app. Posting the complaint
              (grievance post), they have in their local places (After verifying
              with Aadhar card).  A journalist can join a JGR Community by
              signing in with their ID card and Aadhar card.
            </p>
          </div>
          <div>
            <p className="flex items-center px-4 text-lg font-semibold">
              Subscription or Paid Service Data
            </p>
            <p className="px-4 py-2">
              There is no subscription & paid service. JGR is a free service
              Website and Application.{" "}
            </p>
          </div>
          <div>
            <p className="flex items-center px-4 text-lg font-semibold">
              Cookies
            </p>
            <p className="px-4 py-2">
              A cookie is a piece of software code that an internet website
              sends to the browser when accessing information at that site. This
              site does not use cookies except on pages where login is required
              for securely accessing services from this website.{" "}
            </p>
          </div>
          <div>
            <p className="flex items-center px-4 text-lg font-semibold">
              Hyperlink Policy
            </p>
            <p className="flex items-center px-4 text-lg font-semibold">
              Links To External Websites/Portals
            </p>
            <p className="px-4 py-2">
              At many places on this website, you shall find links to other
              websites/portals. These links have been placed for your
              convenience and linked with the Tamil Nadu e-Governance website.{" "}
            </p>
          </div>
          <div>
            <p className="flex items-center px-4 text-lg font-semibold">
              Contact Us
            </p>
            <p className="px-4 py-2">
              Your feedback is always welcome. If you have any queries or
              concerns about our privacy policy, please do not hesitate to call
              during business hours or send us an e-mail at jgr.com.{" "}
            </p>
          </div>
          <p className="py-2 px-4">
            BY ACCESSING AND/OR USING OUR SITES YOU REPRESENT THAT YOU HAVE READ
            AND UNDERSTOOD THIS PRIVACY POLICY.
          </p>
        </div>
      </div>
      {/* <div className="h-0.5 w-full bg-indigo-400"></div> */}
      <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      <div className="py-2">
        <Footer />
      </div>
    </>
  );
}

export default Index;
