import React from "react";
import Fb from "../../Assets/Icons/Fb.svg";
import Twitter from "../../Assets/Icons/Twitter.svg";
import Insta from "../../Assets/Icons/Insta.svg";
import Linkedin from "../../Assets/Icons/Linkedin.svg";
import XBI4 from "../../Assets/Images/XBI4.svg";
import Mail from "../../Assets/Icons/mail.svg";
import "./style.css";
import { useNavigate } from "react-router-dom";

function Index() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };
  return (
    <div>
      <div className=" Footer-main flex flex-row">
        <div>
          Quick Links
          <div className="Footer-Links flex flex-col gap-1 cursor-pointer">
            <div onClick={handleClick}>Home</div>
            <div onClick={() => navigate("/aboutus")}>About us</div>
            <div onClick={() => navigate("/privacypolicy")}>Privacy Policy</div>
            {/* <div>Terms of Service</div> */}
            {/* <div onClick={() => navigate("/support")}>Support</div> */}
            <div>Support</div>
          </div>
        </div>
        <div>
          Contact Us
          <div className="Footer-Links flex flex-col cursor-pointer">
            <div className="flex flex-row">
              <img src={Mail} alt="mail" />
              <div className="pl-1">Email</div>
            </div>
            <div>
              <a href="mailto:info@justgoreal.com">info@justgoreal.com</a>
            </div>
          </div>
        </div>
        <div>
          Get in Touch
          <div className="Footer-icons flex mt-1">
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61552468241702"
            >
              <img className="Footer-icons" src={Fb} alt="Fb" />
            </a>
            <a target="_blank" href="https://twitter.com/justgoreal">
              <img className="Footer-icons" src={Twitter} alt="Twitter" />
            </a>
            <a
              target="_blank"
              href="https://instagram.com/justgoreal?igshid=MzRlODBiNWFlZA=="
            >
              <img className="Footer-icons" src={Insta} alt="Insta" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/just-go-real/about/?viewAsMember=true"
            >
              <img className="Footer-icons" src={Linkedin} alt="Linkedin" />
            </a>
          </div>
        </div>
      </div>
      <div className="Footer-Poweredby flex gap-2 mr-4">
        Powered BY
        <a target="_blank" href="https://www.xbi4.com/">
          <div className="Footer-Logo flex mb-3">
            <img src={XBI4} alt="XBI4" />
          </div>
        </a>
      </div>
    </div>
  );
}

export default Index;
