import { get } from "../../../Utils/Functions/Methods/index";

const GetNewsDetails = async () => {
  try {
    const response: any = await get("/newsPost");
    console.log(response[1][0]); // logging the first news detail
    return response[1]; // Return the response data
  } catch (error) {
    console.log("error", error);
    return null; // Return null or an appropriate value for error cases
  }
};

export default GetNewsDetails;