import { post } from "../../../Utils/Functions/Methods";

export const PostGrievanceDislike = async (user_id: any, grievanceId: any, temp: any = {}) => {
  try {
    const [status, res] = await post(
      `/user/${user_id}/grievancedislike/${grievanceId}`,
      temp
    );

    if (status === 200) {
      console.log("Success");
    } else {
      console.log("Something went wrong");
    }

    return res; // Return the response data
  } catch (err) {
    console.error(err); // It's better practice to use console.error for errors
    return null; // Return null in case of an error
  }
};