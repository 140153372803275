import React, { useContext } from "react";
import { Button, Form } from "react-bootstrap";
import "./style.css";
import { useState } from "react";
import { useEffect } from "react";
import Logo from "../../../Assets/Images/JGRwhite.svg";
import { aadhaar_regex } from "./regex";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { getOtp } from "../../../Utils/Aadhaar/getOtp";
import { submitOtp } from "../../../Utils/Aadhaar/submitOtp";
import apis from "../../../api";

export default function Index() {
  const InitialValues = {
    aadhaar_number: "",
  };
  const navigate = useNavigate();

  // const auth = AuthContext();
  const [disabled, setDisabled] = useState(true);
  const [registration, setRegistration] = useState(InitialValues);
  const [otp, setOtp] = useState<string>();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [aadhaar, setAadhaar] = useState<any>({});
  const [otpbox, setOtpbox] = useState(false);
  const [form, setForm] = useState(true);
  const [otpres, setOtpres] = useState<any>();

  const onValueChange = (key: any) => (value: any) => {
    setRegistration({ ...registration, [key]: value });
  };
  const validate = () => {
    return Boolean(isSubscribed && aadhaar_regex.test(aadhaar));
  };
  useEffect(() => {
    setDisabled(!validate());
  }, [isSubscribed, aadhaar]);

  const { userAttributes }: any = useContext(AuthContext);

  const submitRegistration = async () => {
    const temp = registration;
    console.log("aadhaar data", temp);

    await getOtp(temp)
      .then(([status, res]: any) => {
        if (status === 200) {
          alert("OTP Sent Successful");
          console.log(res, "otp response");
          setOtpres(res);
          setOtpbox(true);
          setForm(false);
        } else {
          alert("Check Aadhar Number");
        }
      })

      .catch((err) => {
        console.log(err);
        alert("Check Aadhar Number");
      });
  };

  async function profileDetails() {
    // Assuming you have access to the user's attributes through a context or a state
    const userId = userAttributes?.sub;
    if (userId) {
      const userProfile = await apis.GetUserProfile(userId);
    }
  }

  const handleOtpSubmit = async () => {
    await submitOtp({
      client_id: otpres?.data?.client_id,
      otp: otp,
      user_id: userAttributes?.sub,
    })
      .then(([status, res]: any) => {
        if (status === 200) {
          alert("Aadhaar Verified Successfully");
          navigate("/Profile");
          profileDetails();
        } else {
          alert("Check Aadhar Number");
        }
      })

      .catch((err) => console.log(err));
  };

  return (
    <>
      <div>
        <div
          className="nav-bar-aadhaar"
          style={{
            backgroundColor: "#488efe",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="brand-logo-lay"
            src={Logo}
            alt="justgoreal"
            style={{
              height: "70px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          />
        </div>
      </div>

      {form == true ? (
        <div className="d-flex justify-center items-center h-[85vh]">
          <div className="form-container d-flex flex-column ">
            <div className="d-flex aadhaar p-5 mb-2">Aadhaar Verification</div>
            <Form className="d-flex flex-row gap-5 ">
              <div className=" d-flex flex-column gap-2">
                <div className="Aadharinput">
                  <Form.Label>Aadhaar Number :</Form.Label>
                  <input
                    onChange={(e) => {
                      onValueChange("aadhaar_number")(e?.target?.value);
                      setAadhaar(e?.target?.value);
                    }}
                    placeholder="Enter Your Aadhaar Number"
                    className="form-control inventory-input"
                  />
                </div>
                <div className="d-flex p-3 text-center justify-center">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="valueCheckbox"
                      onChange={(_) => {
                        onValueChange(
                          setIsSubscribed(Boolean(_.target.checked))
                        );
                        console.log(isSubscribed);
                      }}
                    />
                    Click here to receive OTP
                  </div>
                </div>
              </div>
            </Form>
            <Button
              onClick={() => submitRegistration()}
              className="d-flex align-items-center justify-content-center mx-auto bg-btn"
            >
              Submit
            </Button>
          </div>
        </div>
      ) : null}

      {otpbox == true ? (
        <div className="d-flex justify-content-center align-items-center h-screen">
          <div className="form-container d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex justify-content-center align-items-center aadhaar p-3 fw-bold">
              Submit OTP
            </div>
            <Form className="d-flex flex-row justify-content-center align-items-center gap-5 ">
              <div className=" d-flex flex-column gap-2 auto-cols-max ">
                <div className="d-flex flex-row ">
                  <Form.Label className="fw-bold">One Time Password</Form.Label>
                  <input
                    onChange={(e) => {
                      setOtp(e?.target?.value);
                    }}
                    placeholder="Enter Your OTP"
                    className="form-control inventory-input"
                  />
                </div>
              </div>
            </Form>
            <Button
              onClick={handleOtpSubmit}
              className="d-flex align-items-center justify-content-center mx-auto bg-btn"
            >
              Submit OTP
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
}
