import React, { useContext, useState } from "react";
import ForgetPassword from "../../Assets/Images/Forgetpassword.svg";
import "./style.css";
import { Modal } from "react-bootstrap";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import Visibility from "../../Assets/Svg/visibility.svg";
import Visibilityoff from "../../Assets/Svg/visibility_off.svg";
import { BeatLoader } from "react-spinners";

function Index() {
  const [passwordModal, setPasswordModal] = useState(false);
  const { forgotPassword } = useContext(AuthContext);

  const [username, setUserName] = useState("");
  const [code, setCode] = useState<any>(null);
  const [newPassword, setNewPassword] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleRequest = async () => {
    setLoading(true);
    if (username !== "") {
      await forgotPassword
        .requestCode(username)
        .then((_: any) => {
          setLoading(false);
          // console.log("OTP sent > ", _);

          setPasswordModal(true);
        })
        .catch((err: any) => {
          setLoading(false);

          console.log("error > ", err);
        });
    } else {
      console.log("Field cannot be empty");
    }
  };

  const navigate = useNavigate();
  const onSubmit = async () => {
    setIsLoading(true);
    if (username !== "") {
      if (code !== "") {
        if (newPassword !== "") {
          await forgotPassword
            .setPassword(username, code, newPassword)
            .then((_: any) => {
              setIsLoading(false);
              console.log("password changed > ", _);
              navigate("/authpage");
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log("error > ", err);
            });
        } else {
          setIsLoading(false);
          console.log("All fields are required");
        }
      } else {
        setIsLoading(false);
        console.log("All fields are required");
      }
    } else {
      setIsLoading(false);
      console.log("All fields are required");
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const passwordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <div className="forgetpage-Component">
        <img
          className="forget-pass-img"
          src={ForgetPassword}
          alt="Loginimage"
        />

        <div className="flex shadow-md">
          <div className="forgetpage-box">
            <div className="flex flex-col p-5 justify-center items-center gap-5">
              <p className="font-bold text-lg">Forget Password</p>
              <input
                placeholder="Email"
                className="input-box"
                value={username}
                onChange={(_) => {
                  setUserName(_?.target?.value);
                }}
              />
              {loading ? (
                <div className="flex justify-center">
                  <BeatLoader />
                </div>
              ) : (
                <button
                  type="button"
                  className="otp-button text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-md"
                  onClick={() => {
                    handleRequest();
                  }}
                >
                  Submit OTP
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={passwordModal} onHide={() => setPasswordModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">Forget Password</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body className="modalbody">You will get OTP to </Modal.Body> */}
        <Modal.Body className="text-center">
          <div>
            <input
              placeholder="Enter Mail id"
              className="modalHeadline w-full p-2 text-base border rounded-lg"
              value={username}
              onChange={(_) => {
                setUserName(_?.target?.value);
              }}
            />
          </div>

          <br />

          <input
            className="modalHeadline w-full p-2 text-base border rounded-lg"
            placeholder="Enter OTP"
            value={code}
            onChange={(_) => {
              setCode(_?.target?.value);
            }}
          />
          <br />
          <br />
          <div className="flex flex-row justify-center">
            <input
              type={passwordShown ? "text" : "password"}
              className="modalHeadline w-full p-2 text-base border rounded-lg"
              placeholder="Enter New Password"
              value={newPassword}
              onChange={(_) => {
                setNewPassword(_?.target?.value);
              }}
            />
            <div className="Visibility mt-2">
              {passwordShown ? (
                <img
                  src={Visibility}
                  alt="toggle password visibility"
                  onClick={passwordVisibility}
                />
              ) : (
                <img
                  src={Visibilityoff}
                  alt="toggle password visibility"
                  onClick={passwordVisibility}
                />
              )}
            </div>
          </div>
          <br />
        </Modal.Body>
        <Modal.Footer className="modalButton">
          {isLoading ? (
            <div className="flex justify-center">
              <BeatLoader />
            </div>
          ) : (
            <button
              type="button"
              className=" text-white bg-blue-700 hover:bg-blue-800  
        font-bold rounded-md h-10 w-24 text-lg"
              onClick={() => onSubmit()}
            >
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Index;
