import {get} from "../../../Utils/Functions/Methods/index";

const GetNews = async (user_id: any) => {
  try {
    const response: any = await get(`/newsPost?user_id=${user_id}`);
    return response[1]; // Return the response data
  } catch (error) {
    console.log("error", error);
    return null; // Return null or an appropriate value for error cases
  }
};

export default GetNews